import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Hrms.css';
import Header from './Header';

function HRMS() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const handleClose = () => {
    window.history.back();
  };

  return (
    <div className="breezhr-container">
      <Header />

      {/* Close button at the top-right */}
      <button className="close-button" onClick={handleClose}>✖</button>

      <h1 className="breezhr-title text-dark-blue">BreezHR</h1>

      <img 
        src="https://softronicsys.com/wp-content/uploads/2023/08/HRMS-Software.png"
        alt="BreezHR Platform"
        className="breezhr-main-image"
      />

      <p className="breezhr-intro text-dark-brown">
        BreezHR is a one-stop solution for all HR needs...
      </p>

      <h2 className="breezhr-subtitle text-purple">Our Solutions</h2>
      <div className="breezhr-solutions">
        <p className="text-dark-brown">Our solutions are adept in simplifying your HR management...</p>
        <p className="text-dark-brown">Your HR challenges are as unique as your workforce...</p>
        <p className="text-dark-brown">Oftentimes, HR issues can seem overwhelming...</p>
      </div>

      <h2 className="breezhr-subtitle text-purple">HR Functions Covered by BreezHR:</h2>
      <ul className="breezhr-functions">
        <li className="text-dark-blue">Employee data management</li>
        <li className="text-dark-blue">Compensation management</li>
        <li className="text-dark-blue">Time Entry</li>
        <li className="text-dark-blue">Attendance</li>
        <li className="text-dark-blue">Payroll and regulatory reports</li>
        <li className="text-dark-blue">Performance management</li>
        <li className="text-dark-blue">HR dashboard</li>
      </ul>

      <Link to="/" className="back-button">Back</Link>
    </div>
  );
}

export default HRMS;
