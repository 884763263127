import React, { useEffect } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import './LMSProduct.css';
import Header from './Header';

const LMS = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="breezlearn-container">
      <Header />
      <button className="close-icons" onClick={() => navigate("/")}>
        &times;
      </button>

     

      <h1 className="breezlearn-title">BreezLearn</h1>
      <p className="breezlearn-intro">
        Train your employees, partners, and customers online using our feature-rich LMS that’s so easy to use, you’ll be up and running in minutes.
      </p>

      <h2 className="breezlearn-subtitle">Suits to Your Needs</h2>
      <p>
        We build your own custom domain, logo, and theme. We help to create an environment that is entirely your choice.
      </p>

      <h2 className="breezlearn-subtitle">Build Your Content at Ease</h2>
      <p>
        Our training solutions enable you to build training contents and host them along with the application. We also enable a custom-built assessment process and internal badging/certification.
      </p>

      <h2 className="breezlearn-subtitle">Learning Dashboard</h2>
      <p>
        Provides rich learning insights and dashboards. Eases measuring and tracking ‘Learning compliance and progress’.
      </p>

      <h2 className="breezlearn-subtitle">Always Available on the Cloud</h2>
      <p>
        No need to install, upgrade, or back up anything. BreezLearn is a cloud-powered training platform. Create online training without worrying about hosting.
      </p>

      <h2 className="breezlearn-subtitle">Training Solutions for:</h2>
      <ul className="breezlearn-solutions">
        <li>• Compliance Training</li>
        <li>• On-boarding Training</li>
        <li>• Skill Enhancements Training</li>
        <li>• Remote Workforce Training</li>
      </ul>
      
      <footer>
        <Link to="/" className="back-button">Back</Link>
      </footer>
    </div>
  );
};

export default LMS;
