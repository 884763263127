import React from 'react';
import { Link } from 'react-router-dom';
import './News.css';

function News() {
  return (
    <section className="news" id="news">
      <section className="services" id="services">
        <div className="main-text">
          <h2 className='ei'><span>Our</span> Products</h2>
        </div>

        <div className="services-content">
          {/* Existing Product: HRMS */}
          <div className="box">
            <div className="row">
              <img src="https://softronicsys.com/wp-content/uploads/2023/08/HRMS-Software.png" alt="Group discussion" />
              <div className="layer">
                <Link to="/hrms"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>HRMS</span></h3>
            <p>An HRMS streamlines employee management by automating tasks like payroll, attendance, and performance tracking.</p>
            <Link to="/hrms" className="read">Read More</Link>
          </div>

          {/* Existing Product: LMS */}
          <div className="box">
            <div className="row">
              <img src="https://as2.ftcdn.net/v2/jpg/05/10/58/75/1000_F_510587590_nI7z8EgzqBtTsq8xapL6xepv8KNVgfdZ.jpg" alt="Professional discussion" />
              <div className="layer">
                <Link to="/LMSProduct"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>LMS</span></h3>
            <p>An LMS streamlines online education with tools for course creation, student management, and progress tracking and assessments.</p>
            <Link to="/LMSProduct" className="read">Read More</Link>
          </div>

          {/* Existing Product: Twillo */}
          <div className="box">
            <div className="row">
              <img src="https://www.shutterstock.com/image-photo/woman-using-mobile-phone-bulk-600nw-1290397396.jpg" alt="Small group discussion" />
              <div className="layer">
                <Link to="/Twillo"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>Twillo</span></h3>
            <p>Will my whole audience want to receive this message, or should I only send it to certain recipients? Set and forget SMS automations. Send high volume SMS without sacrificing personalization.</p>
            <Link to="/Twillo" className="read">Read More</Link>
          </div>

          {/* Existing Product: ADA AI */}
          <div className="box">
            <div className="row">
              <img src="https://img.freepik.com/free-photo/ai-technology-microchip-background-digital-transformation-concept_53876-124669.jpg" alt="ADA AI" />
              <div className="layer">
                <Link to="/Ada"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>ADA AI</span></h3>
            <p>Arcus Digital Assistant delivers a complete solution to create conversational 
            experiences for business applications through voice interfaces.</p>
            <Link to="/Ada" className="read">Read More</Link>
          </div>

          {/* New Product: CRM */}
          <div className="box">
            <div className="row">
              <img src="https://img.freepik.com/free-vector/smartphone-shopping-online_23-2147666049.jpg?t=st=1728450511~exp=1728454111~hmac=02b731670928f8d74a501590327513c6d9e42ece7f7c1c46bd2fc66f1f18ad12&w=740" alt="CRM" />
              <div className="layer">
                <Link to="/Shop"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>Shop Floor</span></h3>
            <p>A CRM helps manage customer relationships by organizing contact information, automating follow-ups, and providing insights to improve client engagement.</p>
            <Link to="/Shop" className="read">Read More</Link>
          </div>
        </div>
      </section>
    </section>
  );
}

export default News;
