import React, { useEffect } from 'react';
import './Direct.css'; 
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Direct = () => {
    const navigate = useNavigate();  // useNavigate to handle redirection

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    const handleClose = () => {
        navigate(-1); // This navigates back to the previous page
    };

    return (
        <div className="direct-hire-container">
            <Header />

            {/* Close Icon */}
            <div className="close-icon" onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
            </div>

            <h1 className="direct-hire-heading">Direct Hire</h1>
            <p className="direct-hire-paragraph">
                At Infoway, we provide our clients the flexibility to hire talent permanently when there is a long-term need.
            </p>

            <h2 className="direct-hire-subheading">About Direct Hire</h2>
            <p className="direct-hire-paragraph">
                Direct hire positions are permanent, usually full-time positions with benefits. 
                We facilitate the recruitment and hiring/on-boarding of required talents.
            </p>
            <p className="direct-hire-paragraph">
                Our clients look for direct hire talents when they need multiple candidates with similar skill sets, 
                executive positions, or limited recruiting bandwidth internally.
            </p>

            <h2 className="direct-hire-subheading">Why Choose Infoway?</h2>
            <p className="direct-hire-paragraph">
                At Infoway, we offer the facility of finding the best candidate(s) for your position without burdening your existing staff. 
                We offer customized solutions for your permanent staffing needs.
            </p>

            <h3 className="direct-hire-differentiators-heading">Our Differentiators</h3>
            <ul className="direct-hire-list">
                <li>Catering to staffing needs of companies of all sizes</li>
                <li>Access to a vast repository of pre-screened technically qualified professionals</li>
                <li>Expert recruiters with professional networks and excellent follow-up and follow-through</li>
                <li>Streamlined processes for identifying, recruiting, qualifying, and on-boarding candidates that meet your hiring needs</li>
                <li>24x7 sourcing support</li>
                <li>Stable workforce - 99% of our candidates stay with our clients for beyond 1 year</li>
                <li>True partnership and win-win relationships</li>
            </ul>

            <p className="direct-hire-paragraph">
                For your needs of contract staffing or any questions, please contact us at <a href="mailto:Info@infowaygroup.com" className="direct-hire-email">Info@infowaygroup.com</a>.
            </p>
            <div className="direct-hire-back-button-container">
                <a href="/" className="direct-hire-back-button">Back</a>
            </div>

        </div>
    );
};

export default Direct;
