import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Shop.css';
import Header from './Header';

function ShopFloorControl() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    navigate('/'); // Navigates back to the homepage or any other route you specify
  };

  return (
    <div className="shopfloor-container">
      <Header />
      <button className="closes-button" onClick={handleClose} aria-label="Close">
        &times; {/* Alternatively, you can use an SVG or an icon */}
      </button>
      <h1 className="shopfloor-title">Streamline Your Shop Floor Operations with Our Innovative SaaS Solution!</h1>
      <p className="shopfloor-intro">
        Are you ready to take control of your production floor like never before? Introducing our Shop Floor Control SaaS application, 
        designed to revolutionize the way you prioritize, assign, and track production jobs. Our user-friendly platform empowers employees 
        to seamlessly pick up jobs based on priority, enabling real-time collaboration and maximizing efficiency.
      </p>
      <h2 className="shopfloor-why-title">Why Choose Us?</h2>
      <ul className="shopfloor-features">
        <li><strong>Flexible Job Management:</strong> Allow multiple employees to work on a single job, and assign multiple jobs to a single employee with ease.</li>
        <li><strong>Real-Time Tracking:</strong> Stay updated on job status and progress at every step, reducing downtime and boosting productivity.</li>
        <li><strong>Enhanced Prioritization:</strong> Easily manage production priorities to ensure timely delivery and customer satisfaction.</li>
      </ul>
      <div className="shopfloor-get-started">
        <h2>Join the Future of Shop Floor Management Today!</h2>
        <p>
          Simplify your operations, enhance productivity, and drive growth with our cutting-edge solution.
        </p>
        <Link to="/" className="back-button">Back</Link>
      </div>
    </div>
  );
}

export default ShopFloorControl;
