import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import './Client.css'; // External CSS for better structure
import Header from './Header';

const Client = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="client-containers">
        <Header/>
      <img 
        src="https://infowaygroup.com/sites/all/themes/infowaygroup/images/new/Clients.jpg" 
        alt="Clients" 
        className="client-banner-images"
      />
      <h1 className="client-titles">Our Clients</h1>
      <p className="client-descriptions">
        Info Way Solutions is a premier IT firm specializing in staff augmentation, 
        direct placement, and application development outsourcing for major corporations. 
        Our consultants have expertise across multiple industries and have proudly served 
        the following companies over the years.
      </p>

      <div className="client-logoss">
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/agilant-logo.jpg" 
          alt="Agilant Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/at-and-t-logo.jpg" 
          alt="AT&T Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/allegis-client.png" 
          alt="Allegis Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/altimetrik-client.png" 
          alt="Altimetrik Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/bank-clientlogo.png" 
          alt="Bank Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/lt-technology-servicelogo.jpg" 
          alt="Cardinal Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/motorola-logo-1.jpg" 
          alt="Cisco Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/mphasis-logo.jpg" 
          alt="DirecTV Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/paypal-logo-1.jpg" 
          alt="Disney Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/pantoon-client.png" 
          alt="eBay Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/prokarma-logo.jpg" 
          alt="Google Logo" 
          className="client-logo"
          
        />
         <img 
          src="https://infowaygroup.com/sites/default/files/clients/syntel-logo.jpg" 
          alt="Cardinal Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/cisco.jpg" 
          alt="Cisco Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/direct-tvlogo.jpg" 
          alt="DirecTV Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/disney-logo.jpg" 
          alt="Disney Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/ebay.jpg" 
          alt="eBay Logo" 
          className="client-logo"
        />
        <img 
          src="https://infowaygroup.com/sites/default/files/clients/zensar-logo.jpg" 
          alt="Google Logo" 
          className="client-logo"
          />
            <img 
          src="https://infowaygroup.com/sites/default/files/clients/technology.jpg" 
          alt="Google Logo" 
          className="client-logo"
          />
            <img 
          src="https://infowaygroup.com/sites/default/files/clients/in.jpg" 
          alt="Google Logo" 
          className="client-logo"
          />
               <img 
          src="https://infowaygroup.com/sites/default/files/clients/hewlett.jpg" 
          alt="Google Logo" 
          className="client-logo"
          />
             <img 
          src="https://infowaygroup.com/sites/default/files/clients/santamaria-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
               <img 
          src="https://infowaygroup.com/sites/default/files/clients/logo-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
            <img 
          src="https://infowaygroup.com/sites/default/files/clients/salesforce-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
               <img 
          src="https://infowaygroup.com/sites/default/files/clients/panasonic-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
           <img 
          src="https://infowaygroup.com/sites/default/files/clients/workforcelogiq-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
               <img 
          src="https://infowaygroup.com/sites/default/files/clients/school-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
                     <img 
          src="https://infowaygroup.com/sites/default/files/clients/walmart-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
               <img 
          src="https://infowaygroup.com/sites/default/files/clients/school-client.png" 
          alt="Google Logo" 
          className="client-logo"
          />
      </div>

      
      <Link to="/" className="back-button">Back</Link>
    </div>
  );
};

export default Client;
