import React from 'react'
import './Industries.css'

const Industries = () => {
  return (
    <>
      <div className="bfs-content" id="industry-bfsi">
        
      <h1>BFSI</h1>
      <h2>Banking, Financial Services, and Insurance (BFSI)</h2>
       <img src="path/to/your/image.jpg" alt="Description of the image" className="bfs-image" />

      <p>
        This industry landscape has been undergoing transition at a rapid pace. Pressures of customer retention, product and channel profitability, risk management, and improving financial performance have compelled financial institutions to aggressively implement a range of IT solutions ensuring customer satisfaction.
      </p>
      <p>
        Customers (retail and corporate) have become increasingly discerning in all respects. Various digital channels like mobile, IVR, browser, and ATM have become very important since 24/7 availability of information is a must. With our content framework, financial and non-financial information is distributed through these digital channels seamlessly and securely.
      </p>
      <h3>Our Achievements:</h3>
      <ul>
        <li>Financial solution to enable all bank customers to apply for public issues through stock exchange.</li>
        <li>Loan portfolio for home/personal/commercial on internet and mobile banking.</li>
        <li>Internet, mobile, and SMS banking.</li>
        <li>BI/DW solutions for BFSI clients to enable them to take informed and accurate decisions.</li>
      </ul>
      <h3>Our Services:</h3>
      <p>Our team is adept at providing banking solutions for BFSI in:</p>
      <ul>
        <li>Internet banking</li>
        <li>Mobile and SMS banking</li>
        <li>Public issues for IPOs, rights, mutual funds</li>
        <li>Data warehousing solutions</li>
      </ul>
      <h3>Create business value with our solutions</h3>
      <p>
        Our team of skilled professionals has worked with several financial institutions to simplify complex banking procedures and deploy solutions that can turn heterogeneous data into actionable intelligence.
      </p>
    </div>
      <div className="tech-content"  id="industry-tech">
      <h1>Technology</h1>
      <p>
        The arena of the technology industry is changing progressively. Therefore, technology companies have to update their working methodologies and business models and processes. They have to adapt themselves and stay responsive to complex customer demands.
      </p>
      <p>
        Our experts help technology companies manage their processes in accordance with the changing technology trends. Our technology consultants help companies to update their internal processes and improve their solutions and products. We provide tailored solutions that meet the needs of clients.
      </p>
      <p>
        We have a flexible business and engagement model that makes us an ideal partner for many technology companies. We have successfully provided a diverse range of functional and technical services to various clients all over the globe.
      </p>
      <h3>Our Services:</h3>
      <ul>
        <li>Technology Consulting Services</li>
        <li>Mobility Solutions</li>
        <li>Big Data / BI Analytics and Information Management</li>
        <li>Software and Web Development</li>
        <li>Product Engineering</li>
        <li>System Integration</li>
      </ul>
      <h3>Why Info Way Solutions?</h3>
      <ul>
        <li>
          <strong>Reduce operational costs:</strong> Our technology services help to cut down the technology expenses spent on operations. We provide solutions to every department in an organization to manage operations by implementing robust technology solutions.
        </li>
        <li>
          <strong>Better communication in departments:</strong> Lack of proper communication can make matters worse. We provide a single solution to streamline operations and manage information uniformly. This promotes proper communication, appropriate and updated information sharing, and project collaboration.
        </li>
        <li>
          <strong>Increase in productivity:</strong> Accurate usage of technology tools helps to complete the work prior to the stipulated time. We increase the efficiency of every resource by providing handy tools to manage day-to-day operations.
        </li>
      </ul>
    </div>

      {/* Healthcare Section */}
      <div className="healthcare-content" id="industry-health">
        <h1>Healthcare</h1>
        <p>
          We offer solutions to maintain patient data securely in a cost-effective manner. Our BI solutions provide a wider and safer access to clinical data, which enables healthcare professionals to make sound operational and clinical decisions. This results in streamlining and offering exceptional healthcare experiences to the patients. The BI platform not only helps healthcare professionals access enterprise-wide data but also facilitates data conversion for report and predictive analysis.
        </p>
        <h3>Our Services:</h3>
        <ul>
          <li>Application support</li>
          <li>Revenue Cycle Optimization</li>
          <li>Situational Analysis</li>
          <li>Expectation Surveys</li>
          <li>Problem Solving</li>
          <li>Balanced Scorecards</li>
          <li>Performance Measuring System</li>
          <li>Predictive Modelling</li>
        </ul>
        <h3>Why Info Way Solutions?</h3>
        <ul>
          <li>
            <strong>Save costs:</strong> Our solutions help to optimize revenue cycle optimization by preventing denial of claims.
          </li>
          <li>
            <strong>Improve margins:</strong> By implementing a BI/balanced scorecard, we help our customers improve their margin from 2%-10% each year.
          </li>
          <li>
            <strong>Improve patient satisfaction:</strong> By providing comprehensive reporting and analytical tools, we help customers improve their patient satisfaction by 15%-30%.
          </li>
          <li>
            <strong>Enhance patient care:</strong> By utilizing point-of-care information, we enable customers to identify high-risk and non-compliant patient solutions and improve patient outcomes.
          </li>
        </ul>
        <p>
          Performance management and data management play an important role in the operations sector of any company. We provide the tools and services required to manage these two important factors and also integrate them with our BI services. Our BI services have carved a unique niche in the telecom and health domain, assuring you to enable your company to adapt to continuously changing customer requirements and market trends.
        </p>
      </div>
      <div className="manufacturing-content"  id="industry-manufacturing">
        <h1>Manufacturing</h1>
        <p>
          We have the domain experience and the technological depth to address a range of challenges that the manufacturing industries face. Our team of experts has helped many leading global manufacturing companies leverage IT to optimize their production processes and integrate operational requirements with enterprise-level decision-making.
        </p>
        <p>
          To maintain a competitive edge in this increasingly complex environment, manufacturers today have to look beyond traditional means towards creating new value chains which encourage collaboration, reduce overheads and liabilities, expedite products to market, and understand which products their targeted markets desire.
        </p>
        <h3>Our Expertise:</h3>
        <p>Info Way Solutions specializes in SAP solutions and provides ERP consulting and services to progressive manufacturers thereby acquainting them with:</p>
        <ul>
          <li>Automated solutions to achieve exponential savings and improve profit margins</li>
          <li>Globally competent solutions to sustain competitive edge and increase market share</li>
          <li>Effective information system to increase manufacturing performance</li>
          <li>Our expertise in manufacturing solutions which reduce the time to market and help to outperform the competition</li>
        </ul>
        <h3>Our Manufacturing Solutions Include:</h3>
        <ul>
          <li>Integrated manufacturing solution</li>
          <li>Innovative supply chain solution</li>
          <li>Enterprise integration solution</li>
          <li>Enterprise resource planning solution</li>
          <li>Portal solution</li>
          <li>EDI integration</li>
          <li>Engineering design services</li>
        </ul>
      </div>
      <div className="life-sciences-container"  id="industry-life-sciences">
      <h1>Life Sciences</h1>
      <p>
        The Life Sciences industry is an evergreen sector as it rarely faces the recession blues. In the last few years, this sector has grown by leaps and bounds.
      </p>

      <h2>Challenges Faced</h2>
      <ul>
        <li>Keeping pace with changing government policies and regulations</li>
        <li>Stringent processes for quality and manufacture compliance</li>
        <li>Rising costs for R&D</li>
        <li>Tracking patent processes</li>
      </ul>

      <h2>Our Solutions for the Pharmaceutical Industry</h2>
      <ul>
        <li>Master Data Management</li>
        <li>BI and Reporting Service</li>
        <li>Mobility Solution for Marketing</li>
        <li>Customer Relationship Management</li>
        <li>Medication Order Management System</li>
        <li>Regulatory Compliance</li>
      </ul>

      <h2>Why InfoWay Solutions?</h2>
      <ul>
        <li>Solutions for better compliance with regulatory guidelines</li>
        <li>Tracking drug discovery processes and management</li>
        <li>Processes for clinical trial project management and tracking</li>
        <li>Life sciences quality process and compliance tracking</li>
        <li>BI and regulatory reports</li>
      </ul>
    </div>
    <div className="telecom-container"  id="industry-telecom">
      <h1>Telecom</h1>
      <p>
        The demanding needs and preferences of consumers in the telecom industry have led to a faster churn-out of telecom products and services. However, factors like increasing competition, changing regulations, converging business boundaries, time-bound production schedules, and the time to market have prevented vendors from discerning exact client requirements.
      </p>

      <p>
        While the industry has shifted from product-centric to consumer-centric operations, there still exists a gap between analyzing customers’ needs and modifying business processes to meet those unique needs. This scenario is a testament to the premise that Business Intelligence (BI) integrated with traditional Client Relationship Management (CRM) solutions can create substantial profit in the existing CRM environment.
      </p>

      <p>
        Business Intelligence (BI) enables companies to make informed decisions based on available or mined data existing in different networks. We at InfoWay Solutions can assist telecom vendors in managing data revolving around consumers through comprehensive BI and Data Warehousing (DW) solutions.
      </p>

      <h2>Our BI/DW Services for the Telecom Industry</h2>
      <ul>
        <li>Business Intelligence</li>
        <li>Predictive Analysis</li>
        <li>Ad hoc Reporting</li>
      </ul>

      <h2>Benefits of Our BI/DW Services</h2>
      <ul>
        <li>Locate the latest revenue streams and methods, converging all functions for enhancing client experience.</li>
        <li>Assess particular Call Detail Records (CDR) with quantifiable results.</li>
        <li>Improve logical operations of the firm for supervising fiscal information and assessing business models to create dependence amongst clients.</li>
        <li>Create robust safety measures for clients' fiscal and personal information.</li>
        <li>Provide meaningful information on client transactions for formulation of better service policies.</li>
        <li>Provide data on services and distribution along with functional data extracted from all commercial sectors.</li>
        <li>Supervise workforce expenditures on customer care and technical support by optimizing individuals' competence.</li>
      </ul>
    </div>
    <div className="retail-container"  id="industry-retail">
      <h1>Retail</h1>
      <p>
        Retail companies operate in environments that are highly dynamic, with new challenges and opportunities emerging constantly. Retailers need to provide their customers with new ways to interact and shop while at the same time meeting the abiding challenges of the business: winning consumers while driving down costs.
      </p>

      <p>
        We understand that retailers must constantly innovate in order to meet the changing consumer demands and to compete globally.
      </p>

      <h2>Our Services</h2>
      <ul>
        <li>
          <strong>Multi-channel Retailing:</strong> The more your consumers see you, the more they will want to know about you. A true multi-channel strategy covers purchases from various channels such as stores, websites, telephone ordering, mail orders, interactive television, catalog ordering, and comparison shopping sites.
        </li>
        <li>
          <strong>Mobility in Retail:</strong> Consumers are ready to use mobile devices to shop, compare, and transact both online and in stores. Retailers are increasingly adopting mobile technology at checkout to enhance customer engagement and loyalty.
        </li>
        <li>
          <strong>Inventory Management:</strong> Effective inventory management is crucial for retailers. IT systems help retailers plan stock outs, replenish stock timely, and maintain adequate inventory to match consumer preferences while reducing costs.
        </li>
        <li>
          <strong>Digital Consumers:</strong> Today's consumers have access to information at their fingertips about product assortments, pricing, and quality. Companies can create more wallet share by engaging with consumers across digital channels.
        </li>
        <li>
          <strong>Virtual Retailing:</strong> Virtual Retailing is the future, allowing customers to experience store items just like in a physical store. Targeting customers beyond physical locations reduces costs and increases profitability.
        </li>
      </ul>

      <h2>Why InfoWay Solutions?</h2>
      <p>
        Our experts work in collaboration with you to build solutions that will address your unique challenges. We create smart and inherent shopping experiences by providing services such as:
      </p>
      <ul>
        <li>Content Management</li>
        <li>WAP and Mobile Application Development (e.g., Store Locator)</li>
        <li>Customized Enterprise Solutions</li>
        <li>Search Engine Optimization</li>
        <li>Website Design, Development, Re-Development, and Maintenance</li>
        <li>Automated Fulfillment Process (Vouchers, Ticketing, Dispatch)</li>
        <li>Business Analytics & Reporting (Channel Performance, Product Performance)</li>
        <li>Staffing Support</li>
        <li>E-Commerce Applications</li>
        <li>Intranet Solutions</li>
      </ul>
    </div>
    <div className="travel-hospitality-container"  id="industry-travel-hospitality">
      <h1>Travel & Hospitality</h1>
      <p>
        The constant evolution of the travel and hospitality industry has revolutionized this segment. With globalization, it has become even more critical to keep pace with the changing transactional, developmental, and operational demands of customers while maximizing value. InfoWay Solutions has the expertise to design and develop technology solutions to cater to the growing demands of consumers. To achieve greater customer sustenance and loyalty, companies need to adopt numerous measures and keep themselves ahead in a competitive environment. Digital technology represents a milestone for the travel and hospitality industry.
      </p>

      <p>
        Technological advancements and changing trends have boosted the travel industry. Property websites, email marketing, mobile technology, social, and visual media are some of the transforming factors in the travel and hospitality industry. InfoWay Group offers holistic solutions to enable excellent business performance by providing these solutions and services.
      </p>

      <h2>Our Travel & Hospitality Services</h2>
      <ul>
        <li>Website Design and Redesign</li>
        <li>Website Hosting and Maintenance</li>
        <li>Business and E-Commerce Solutions</li>
        <li>Content Management Systems and Web Crawling</li>
        <li>Business and Enterprise Solutions</li>
        <li>WAP/Mobile Applications</li>
      </ul>

      <h2>Why InfoWay Group?</h2>
      <p>
        Our innovative and efficient use of mobile technology includes:
      </p>
      <ul>
        <li>Utilizing social networks to enhance brand image, retain customers, and influence travel decisions for new customers.</li>
        <li>Personalization, behavioral marketing, and offer optimization by providing relevant information.</li>
        <li>Reducing launch windows (from conceptualization to deployment) for launching new products and services using cloud services.</li>
        <li>Ability to convert social media information into valuable Business Intelligence (BI).</li>
        <li>Improving revenue management by optimizing pricing models.</li>
        <li>Implementing cloud computing for the travel and hospitality segment.</li>
        <li>Creating multilingual websites.</li>
      </ul>
    </div>
    </>
  )
}

export default Industries
