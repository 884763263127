import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Header.css';


function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isIndustriesDropdownOpen, setIsIndustriesDropdownOpen] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false); // State for Products dropdown
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Mobile menu state
  const [isStaffingDropdownOpen, setIsStaffingDropdownOpen] = useState(false);
  const [isAboutUsDropdownOpen, setIsAboutUsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const industriesDropdownRef = useRef(null);
  const productsDropdownRef = useRef(null); // Ref for Products dropdown
  const staffingDropdownRef = useRef(null);
  const aboutUsDropdownRef = useRef(null);

  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleRedirect = () => {
    navigate('/');
  };

  const toggleIndustriesDropdown = () => {
    setIsIndustriesDropdownOpen(!isIndustriesDropdownOpen);
  };

  const toggleProductsDropdown = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen); // Toggle Products dropdown
  };
  const toggleStaffingDropdown = () => {
    setIsStaffingDropdownOpen(!isStaffingDropdownOpen); // Toggle Staffing dropdown
  };
  const toggleAboutUsDropdown = () => {
    setIsAboutUsDropdownOpen(!isAboutUsDropdownOpen); // Toggle About Us dropdown
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
    if (industriesDropdownRef.current && !industriesDropdownRef.current.contains(event.target)) {
      setIsIndustriesDropdownOpen(false);
    }
    if (productsDropdownRef.current && !productsDropdownRef.current.contains(event.target)) {
      setIsProductsDropdownOpen(false); // Close Products dropdown if clicked outside
    }
    if (staffingDropdownRef.current && !staffingDropdownRef.current.contains(event.target)) {
      setIsStaffingDropdownOpen(false);
    }
    if (aboutUsDropdownRef.current && !aboutUsDropdownRef.current.contains(event.target)) {
      setIsAboutUsDropdownOpen(false); // Close About Us dropdown if clicked outside
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
     <a href="#" className="logo" onClick={handleRedirect}> 
  <img 
    src="https://www.infowaygroup.com/sites/all/themes/infowaygroup/images/logooo.jpg" 
    alt="Logo" 
    style={{ width: '100px', height: 'auto' }} 
  />
</a>
      
      <ul className={`navlist ${isMobileMenuOpen ? 'active' : ''}`}>
      <li className="dropdown" ref={dropdownRef}>
  <a href="#" onClick={toggleDropdown} className="dropdown-toggle nav-item">
    Services
    <ChevronDown 
      className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`} 
      size={20}
    />
  </a>
  {isDropdownOpen && (
    <ul className="dropdown-menu active">         
      <li><Link to="/services/0" className="nav-item">Application Modernization</Link></li>
      <li><Link to="/services/1" className="nav-item">Big Data Solution</Link></li>
      <li className="dropdown" ref={staffingDropdownRef}>
                <button className="nav-item" onClick={toggleStaffingDropdown}>Staffing Services</button>
                {isStaffingDropdownOpen && (
                  <ul className="dropdown-submenu">
                    <li><Link to="/contractservice" className="nav-item">Contract Staffing</Link></li>
                    <li><Link to="/Msp" className="nav-item">MSP Staffing</Link></li>
                    <li><Link to="/Direct" className="nav-item">Direct Hire</Link></li>
                    <li><Link to="/Onsite" className="nav-item">Onsite Requirements</Link></li>
                  </ul>
                )}
              </li>
      <li><Link to="/services/3" className="nav-item">Web UI/UX Development</Link></li>
      <li><Link to="/services/4" className="nav-item">Cyber Security Solution</Link></li>
      <li><Link to="/services/5" className="nav-item">Mobile Application Development</Link></li>
      <li><Link to="/services/6" className="nav-item">Robotic Process Automation</Link></li>
      <li><Link to="/services/7" className="nav-item">Custom Application Developement</Link></li>
    </ul>
  )}
</li>

<li className="dropdown" ref={industriesDropdownRef}>
  <a href="#" onClick={toggleIndustriesDropdown} className="dropdown-toggle nav-item">
    Industries
    <ChevronDown 
      className={`dropdown-arrow ${isIndustriesDropdownOpen ? 'open' : ''}`} 
      size={20}
    />
  </a>
  {isIndustriesDropdownOpen && (
    <ul className="dropdown-menu active">
      <li><Link to="/industries/bfsi" className="nav-item">BFSI</Link></li>
      <li><Link to="/industries/healthcare" className="nav-item">Healthcare</Link></li>
      <li><Link to="/industries/technology" className="nav-item">Technology</Link></li>
      <li><Link to="/industries/aerospace" className="nav-item">Aerospace and Defense</Link></li>
      <li><Link to="/industries/manufacturing" className="nav-item">Manufacturing</Link></li>
      <li><Link to="/industries/life-sciences" className="nav-item">Life Sciences</Link></li>
      <li><Link to="/industries/telecom" className="nav-item">Telecom</Link></li>
      <li><Link to="/industries/retail" className="nav-item">Retail</Link></li>
      <li><Link to="/industries/travel-hospitality" className="nav-item">Travel & Hospitality</Link></li>
    </ul>
  )}
</li>
        <li className="dropdown" ref={productsDropdownRef}>
          <a href="#" onClick={toggleProductsDropdown} className="dropdown-toggle nav-item">
            Products
            <ChevronDown 
              className={`dropdown-arrow ${isProductsDropdownOpen ? 'open' : ''}`} 
              size={20}
            />
          </a>
          {isProductsDropdownOpen && (
            <ul className="dropdown-menu active">
              <li><Link to="/hrms" className="nav-item">HRMS Solution</Link></li>
              <li><Link to="/LMSProduct" className="nav-item">LMS Solution</Link></li>
              <li><Link to="/Twillo" className="nav-item">Twillo</Link></li>
              <li><Link to="/Ada" className="nav-item">ADA AI</Link></li>
              <li><Link to="/Shop" className="nav-item">Shop Floor</Link></li>
            </ul>
          )}
        </li>
        <li><a href="/Insights" className="nav-item">Insights</a></li>
        <li className="dropdown" ref={aboutUsDropdownRef}>
  <Link 
    to="/about-us" 
    onClick={toggleAboutUsDropdown} 
    className="dropdown-toggle nav-item"
  >
    About Us
    <ChevronDown 
      className={`dropdown-arrow ${isAboutUsDropdownOpen ? 'open' : ''}`} 
      size={20}
    />
  </Link>
  
  {isAboutUsDropdownOpen && (
    <ul className="dropdown-menu active">
      <li>
        <Link to="/Lead" className="nav-item">Leadership Teams</Link>
      </li>
    </ul>
  )}
</li>

        <li><a href="#careers" className="nav-item">Careers</a></li>
        <li><a href="/Client" className="nav-item">Clients</a></li>
      </ul>
      
      <div className="menu-icon" id="menu-icon" onClick={toggleMobileMenu}>☰</div> {/* Add hamburger icon */}
    </header>
  );
}

export default Header;
