import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';  // Import YouTube icon

function Footer() {
  return (
    <section className="end">
      <div className="last-text">
        <pre>© 2024 Info Way Solutions. All Rights Reserved.</pre>
      </div>
      <div className="con">
        <a href="https://www.linkedin.com/company/infoway-solutions/mycompany/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://www.instagram.com/infowaysolutionsllc/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://www.youtube.com/channel/UC1ENJHoYQM9qnt7bUU5ZBzQ" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
      </div>
    </section>
  );
}

export default Footer;
