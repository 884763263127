import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import './Onsite.css'; // Optional CSS for custom styling

const Onsite = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    
  return (
    <div className="onsite-recruitment">
        <Header/>
      <h2  className="heading">Onsite Recruitment</h2>
      <p>
        Quite often, an organization’s human capital initiatives require on-site assistance to meet their staffing objectives. In such cases, Infoway can provide onsite recruiting services to quickly and efficiently assist clients with their internal recruitment efforts. Our services are tailor-made and aligned with our clients' business needs.
      </p>
      
      <p>
        At Infoway, we commission our recruitment services to be delivered at the client's onsite location, as needed. Our recruiters work in collaboration with your organization to locate candidates, interview prospects, and facilitate the hiring process.
      </p>
      
      <h3>Onsite Recruiting Manager Responsibilities</h3>
      <ul>
        <li>Help define your staffing needs (temporary and permanent placement)</li>
        <li>Manage recruiting, sourcing, screening resumes, staffing, scheduling, payroll, and billing</li>
        <li>Supervise your existing vendors and negotiate discounts where appropriate</li>
        <li>Check all background and work references</li>
        <li>Handle all employee negotiations</li>
        <li>Monitor satisfaction</li>
      </ul>
      
      <p>
        At the heart of our onsite recruitment service is our ability to ‘blend in’ and often become integral to our clients’ operations.
      </p>
      
      <p>
        We can be relied upon to assist not only in recruitment but also in areas such as shift patterns, payroll, legislation, employment law, and other HR matters.
      </p>
      
      <p>In short, we provide all our expertise right at your doorstep.</p>
      
      <p>
        <strong>For your needs, please contact us at <a href="mailto:Info@infowaygroup.com">Info@infowaygroup.com</a></strong>
      </p>
      <Link to="/" className="back-button">Back</Link>
    </div>

  );
};

export default Onsite;
