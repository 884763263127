import React, { useEffect, useRef } from 'react';
import './Contact.css';

function Contact() {
  const branches = [
    // ... (previous branch data remains unchanged)
   
      {
        name: "Fremont Office (Global HQ)",
        address: "46520 Fremont Blvd, Suite 614, Fremont, CA 94538",
        phone: "+16319238350",
        email: "info@infowaygroup.com",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3154.640994436012!2d-121.96320278468186!3d37.492238879811236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fc74c2e0f0cf1%3A0x5a9b073a4817a2ed!2s46520%20Fremont%20Blvd%20%23614%2C%20Fremont%2C%20CA%2094538!5e0!3m2!1sen!2sus!4v1696964069619!5m2!1sen!2sus"
      },
      {
        name: "Los Angeles Office",
        address: "18766, Pioneer Blvd, Suite 614 Artesia, CA – 91326",
        phone: "+925-640-6720",
        email: "LA@infowaygroup.com",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.1701276110457!2d-118.08387158522883!3d33.86194758066198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2d4d7706a40d5%3A0xe3c8a36b4bafed4a!2s18766%20Pioneer%20Blvd%2C%20Artesia%2C%20CA%2091326%2C%20USA!5e0!3m2!1sen!2sin!4v1697045577754!5m2!1sen!2sin"
      },
      {
        name: "Puducherry Office",
        address: "40, First Floor, Rajiv Gandhi Street, Senthamarai Nagar, Muthialpet, Puducherry - 605003",
        phone: "+91-8056655291",
        email: "puducherry@infowaygroup.com",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.1508467851243!2d79.83158241480045!3d11.940417791534272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5361c5b857b9f5%3A0x8d6e34f8e1bd7ae1!2sRajiv%20Gandhi%20Street%2C%20Senthamarai%20Nagar%2C%20Muthialpet%2C%20Puducherry%20605003%2C%20India!5e0!3m2!1sen!2sin!4v1697045654860!5m2!1sen!2sin"
      },
      {
        name: "Chennai Office",
        address: "8/17, Second Floor, Tambaram-Velacherry Main Road, Vijayanagaram, Medavakkam, Chennai - 600100.",
        phone: "+91-9500505590",
        email: "chennai@infowaygroup.com",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3872.413452492983!2d80.21349971483074!3d12.928470190885675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525df97a99b4f3%3A0x2b5fa4b7b3c119ac!2sVijayanagaram%2C%20Medavakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600100%2C%20India!5e0!3m2!1sen!2sin!4v1697045732702!5m2!1sen!2sin"
      },
      {
        name: "Canada Office",
        address: "7030 Woodbine Avenue, Suite 500, Markham Ontario L3R 6G2, Canada",
        email: "canada@infowaygroup.com",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.911401907762!2d-79.3658962844976!3d43.82454537911608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2af217a781c5%3A0x716672a946fc841d!2s7030%20Woodbine%20Ave%2C%20Markham%2C%20ON%20L3R%206G2%2C%20Canada!5e0!3m2!1sen!2sin!4v1697045804042!5m2!1sen!2sin"
      },
      {
        name: "Singapore Office",
        address: "160 Robinson Road, #14-04, Singapore Business Federation Centre, Singapore - 068914.",
        email: "singapore@infowaygroup.com",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.164478832728!2d103.84798181474906!3d1.2782572621215775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190d14a67467%3A0x2e9b5ddc86b3141c!2s160%20Robinson%20Rd%2C%20Singapore%20068914!5e0!3m2!1sen!2sin!4v1697045852630!5m2!1sen!2sin"
      },
      {
        name: "Baroda Office",
        address: "301, Sidcup Tower, Race Course Rd, Race Course, Gautam Nagar, Vadiwadi, Vadodara, Gujarat 390007",
        email: "baroda@infowaygroup.com",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3716.5069725250587!2d73.18306531476785!3d22.301870485321455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc63284dbbf8b%3A0x531150a34d8823d6!2sSidcup%20Tower%2C%20Race%20Course%2C%20Gautam%20Nagar%2C%20Vadiwadi%2C%20Vadodara%2C%20Gujarat%20390007!5e0!3m2!1sen!2sin!4v1697045934012!5m2!1sen!2sin"
      }
    ];
    
  

  const clients = [
    { name: 'HCL', logo: 'https://www.hcltech.com/themes/custom/hcltech/images/hcltech-new-logo.svg' },  // Replace with actual logo path
    { name: 'Google', logo: 'https://www.google.co.in/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png' },
    { name: 'Cisco', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqHxfp5_IxQLcw1D8CVTi6ouBWcTy2m6sxHw&s' },
    { name: 'L&T', logo: 'https://www.larsentoubro.com/media/46702/logo.png' },
    { name: 'Paypal', logo: 'https://www.paypalobjects.com/webstatic/i/logo/rebrand/ppcom.png' },
   
    { name: 'Motorola', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGuFE_HngigTIZjLe5M2DBvsr4GTIfmCjaLg&s' },
    { name: 'Ephasis', logo: 'https://companieslogo.com/img/orig/MPHASIS.NS_BIG-96e12b36.png?t=1720244492' },
    { name: 'SLI', logo: 'https://upload.wikimedia.org/wikipedia/en/e/e3/SLI_Systems_Logo.png' },
    { name: 'L&T', logo: ' https://www.pharmaceuticalprocessingworld.com/wp-content/uploads/2023/01/Agilent-logo.png' },
    { name: 'AT&T', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5qRb0CJYNfs1XSsDlC1pAArV1pLAh-NCV5Q&s' },
    { name: 'L&T', logo: 'https://www.larsentoubro.com/media/46702/logo.png' },
  
  ];
 

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollInterval;
    let isHovered = false;

    const startScroll = () => {
      scrollInterval = setInterval(() => {
        if (!isHovered && slider.scrollLeft < slider.scrollWidth - slider.clientWidth) {
          slider.scrollLeft += 1;
        } else if (!isHovered) {
          slider.scrollLeft = 0;
        }
      }, 20);
    };

    const stopScroll = () => {
      clearInterval(scrollInterval);
    };

    const handleMouseEnter = () => {
      isHovered = true;
      stopScroll();
    };

    const handleMouseLeave = () => {
      isHovered = false;
      startScroll();
    };

    slider.addEventListener('mouseenter', handleMouseEnter);
    slider.addEventListener('mouseleave', handleMouseLeave);

    startScroll();

    return () => {
      stopScroll();
      slider.removeEventListener('mouseenter', handleMouseEnter);
      slider.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <section className="contact" id="contact">
      <div className="contact-text">
        <h2 className='hi'>Get in <span>Touch</span></h2>
        <p>We are a passionate digital design agency that specializes in beautiful and easy-to-use digital design & web development services.</p>
      </div>
      
     
      <div className="branches-grid">
  {branches.map((branch, index) => (
    <address key={index} className="branch">
      <h3>{branch.name}</h3>
      <p>{branch.address}</p>
      {branch.phone && (
        <p>Phone: <a href={`tel:${branch.phone}`} className="contact-link phone-link">{branch.phone}</a></p>
      )}
      <p>Email: <a href={`mailto:${branch.email}`} className="contact-link email-link">{branch.email}</a></p>
      <p>Location</p>
      <iframe
        src={branch.mapUrl}
        width="70%" /* Set this to a specific percentage or pixel value if needed */
        height="70" /* Adjust the height here for a smaller map */
        style={{ border: 0, borderRadius: '5px', maxHeight: '150px' }} /* Adjust height here as well */
        allowFullScreen=""
        loading="lazy"
        title={`Map of ${branch.name}`}
      ></iframe>
    </address>
  ))}
</div>



      <div className="clients-section">
      <h3 className="clients-heading">Our clientele</h3>

        <div className="clients-slider" ref={sliderRef}>
          {[...clients, ...clients].map((client, index) => (
            <div key={index} className="client-logo">
              <img src={client.logo} alt={client.name} />
            </div>
          ))}
        </div>
      </div>

      {/* <div className="contact-form">
        <form action="">
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email Address" required />
          <input type="tel" name="mobile" placeholder="Your Mobile Number" required />
          <textarea name="message" cols="35" rows="10" placeholder="How Can I Help You" required></textarea>
          <input type="submit" value="Send Message" className="submit" />
        </form>
      </div> */}
      {/* Rest of the component remains unchanged */}
    </section>
  );
}

export default Contact;