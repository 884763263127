import React from 'react';
import './Read.css'; // Ensure to create a CSS file for styles
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

const ReadingList = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className="reading-list-container">
      <Header/>
        <img 
        src="https://infowaygroup.com/blog/blog_images/reading.jpg" 
        alt="Managing Internal Staff" 
        className="four-image"
      />
      <h1 className="reading-list-title">Are You Reading the Right Stuff to Progress Your Career?</h1>
      <p className="reading-list-intro">
        What types of things should you be reading to broaden your knowledge on business subjects 
        with an aim of progressing your career opportunities? Update your reading list here!
      </p>

      <h2 className="reading-list-subtitle">Ever Notice the Back Wall?</h2>
      <p className="reading-list-paragraph">
        We’ve all been on a Skype, Zoom, or Teams web conference at one point or another and noticed 
        what’s in the background of the people we’re talking to. Rest assured, some of them will have 
        overflowing bookshelves boasting a bedazzling array of bloated tomes on business and current 
        affairs subjects. Does it ever make you wonder what YOU should have on your back wall? Is your 
        career being impacted by the fact you don’t read enough?
      </p>

      <h2 className="reading-list-subtitle">Even Successful People Make Time to Read</h2>
      <p className="reading-list-paragraph">
        I’m not sure if you’ve ever heard interviews from industry leaders like Bill Gates and Richard 
        Branson regarding their thirst for reading. Sure, not every business leader can chart their 
        success based on the strength of their reading list, but it’s noticeable how many successful 
        people say they take time to read. And read a lot.
      </p>

      <h2 className="reading-list-subtitle">Types of Sources</h2>
      <p className="reading-list-paragraph">
        Thanks to Google and Amazon, knowledge is plentiful and accessible these days. No more long 
        walks in the rain down to the local library only to find they have a miserable choice of 
        volumes on the topics you care about. These days, you can think of any topic that comes into 
        your head, and you will find tens, if not hundreds, of articles, journals, and books a 
        mouse-click away.
      </p>

      <h3 className="reading-list-key-areas-title">Key Areas to Think About:</h3>
      <ul className="reading-list-key-areas">
        <li className="reading-list-key-area">
          <strong>News and Blog Sites:</strong> Sites like Medium, Reddit, and Mashable are popular 
          places to learn new things about your career passion or subject.
        </li>
        <li className="reading-list-key-area">
          <strong>Suppliers and Vendors:</strong> Most suppliers will publish a blog and provide 
          regular updates on their site covering topics that have currency.
        </li>
        <li className="reading-list-key-area">
          <strong>Industry Bodies and Professional Organizations:</strong> Articles from these 
          organizations are written BY industry professionals FOR industry professionals.
        </li>
        <li className="reading-list-key-area">
          <strong>Books:</strong> Sometimes reading a book is the very best way to dig deep into a 
          topic. Ask colleagues or bosses for their recommendations!
        </li>
        <li className="reading-list-key-area">
          <strong>Research Reports, Articles, and White Papers:</strong> Google searches can source a 
          variety of research reports and articles.
        </li>
        <li className="reading-list-key-area">
          <strong>Alternatives to Reading – Podcasts and Videos:</strong> If you’re not a great reader, 
          consider exploring videos and podcasts, which offer valuable insights.
        </li>
      </ul>

      <h2 className="reading-list-final-thought-title">Final Thought</h2>
      <p className="reading-list-final-thought">
        Reading can help your career, no matter what you read. People who read more have larger 
        vocabularies, which can make all the difference during a job interview. Being articulate and 
        well-spoken will help you in any profession, boosting your self-confidence immensely.
      </p>
      <Link to="/" className="back-button">Back</Link>
    </div>
  );
};

export default ReadingList;
