import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Contract.css';
import Header from './Header';
import { FaTimes } from 'react-icons/fa'; 
 

const Contract = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className="contract-staffing">
      <Header/>
      <Link to="/" className="close-icon">
        <FaTimes />
      </Link>
      <h1 className="heading">Contract Staffing</h1>
      <p>
        Infoway is a one-stop source for all requirements of contract staffing. Clients can get single source management for all their staffing requirements. Staffing is made easier, without sacrificing quality and competency.
      </p>
      
      <h2 className="subHeading">Benefits of Contract Staffing</h2>
      <ul>
        <li>An immediate solution to any staffing problem</li>
        <li>Increased productivity for their entire team</li>
        <li>Extra time for their departments to utilize in other areas</li>
        <li>Confidence that they are being presented with the best possible candidate for their position</li>
        <li>Guarantee that if they are not satisfied with their candidate, we will find them a suitable replacement and do it in record time.</li>
      </ul>

      <p>
        For those companies seeking to access talent and tweak their workforce as needs arise, Infoway offers flexible, effective, and short/long term staffing solutions. If your projects need immediate resources, count on us for quality and a quick timeline, with no employee-related costs.
      </p>

      <p>
        Our expert team members provide efficient solutions, matching the right skills and talents to meet job challenges.
      </p>

      <h2 className="subHeading">Flexible Solutions</h2>
      <p>
        Infoway offers flexible solutions for seasonal and cyclic hiring and responds to these requests within 24 hours.
      </p>
      
      <p>
        We follow a disciplined approach that will be unique to the job role and the industry demands.
      </p>

      <p>
        Getting employees for a short-term role that will help businesses the much-needed flexibility.
      </p>

      <p>
        Our team has years of experience in handling different industry requirements for contractual recruitment. We have an updated and well-maintained repository of candidates who are willing to work on a temporary basis and can handle diverse roles.
      </p>

      <p>
        Assessing the need and the immediate objective of the business and providing a line-up of effective and productive candidates.
      </p>

      <h2 className="subHeading">Contact Us</h2>
      <p>
        For your needs of contract staffing or any questions, please contact us at <a href="mailto:info@infowaygroup.com">info@infowaygroup.com</a>.
      </p>
      <Link to="/" className="back-button">Back</Link>
    </div>
  );
};

export default Contract;
