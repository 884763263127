import React from 'react'
import Header from './Header'
import Services from './Services'
import About from './About'
import News from './News'
import Footer from './Footer'
import Home from './Home'
import Contact from './Contact'

const Page = () => {
  return (
    <div>
      <Header />
        <Home />
        <Services />
        {/* <About /> */}
        <News />
      <Contact />
      <Footer />
    </div>
  )
}

export default Page
