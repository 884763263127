import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page from './Page';
import ServiceDetail from './ServiceDetail';
import Hrms from './Hrms';
import LMSProduct from './LMSProduct';
import Twillo from './Twillo';
import Ada from './Ada';
import Industries from './Industries';
import IndustryDetail from './IndustryDetail';
import View from './View';
import Shop from './Shop';
import Contract from './Contract';
import Msp from './Msp';  // Corrected case 
import Direct from './Direct';  // Corrected case
import Onsite from './Onsite';  // Corrected case
import Lead from './Lead';
import Insights from './Insights';
import Four from './Four';
import Small from './Small';
import Read from './Read';
import Job from './Job';
import Work from './Work';
import Client from './Client';  // Corrected case

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Main layout (Page component) */}
        <Route path="/" element={<Page />} />

        {/* Dynamic route for services */}
        <Route path="services/:id" element={<ServiceDetail />} />

        {/* Updated route for HRMS and LMS */}
        <Route path="/hrms" element={<Hrms />} />
        <Route path="/lmsproduct" element={<LMSProduct />} />
        <Route path="/twillo" element={<Twillo />} />
        <Route path="/ada" element={<Ada />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/contractservice" element={<Contract />} />
        <Route path="/msp" element={<Msp />} />  {/* Lowercase "msp" in the route */}
        <Route path="/direct" element={<Direct />} />  {/* Lowercase "direct" in the route */}
        <Route path="/onsite" element={<Onsite />} />  {/* Lowercase "onsite" in the route */}
        <Route path="/lead" element={<Lead />} />
        
        {/* Industries routes */}
        <Route path="/industries" element={<Industries />} />
        <Route path="/industries/bfsi" element={<IndustryDetail industry="bfsi" />} />
        <Route path="/industries/healthcare" element={<IndustryDetail industry="healthcare" />} />
        <Route path="/industries/technology" element={<IndustryDetail industry="technology" />} />
        <Route path="/industries/aerospace" element={<IndustryDetail industry="aerospace" />} />
        <Route path="/industries/manufacturing" element={<IndustryDetail industry="manufacturing" />} />
        <Route path="/industries/life-sciences" element={<IndustryDetail industry="life-sciences" />} />
        <Route path="/industries/telecom" element={<IndustryDetail industry="telecom" />} />
        <Route path="/industries/retail" element={<IndustryDetail industry="retail" />} />
        <Route path="/industries/travel-hospitality" element={<IndustryDetail industry="travel-hospitality" />} />
        
        {/* Additional Routes */}
        <Route path="/insights" element={<Insights />} />  {/* Lowercase path for Insights */}
        <Route path="/four" element={<Four />} />  {/* Lowercase path for Four */}
        <Route path="/small" element={<Small />} />  {/* Lowercase path for Small */}
        <Route path="/read" element={<Read />} />  {/* Lowercase path for Read */}
        <Route path="/job" element={<Job />} />  {/* Lowercase path for Job */}
        <Route path="/work" element={<Work />} />  {/* Lowercase path for Work */}
        <Route path="/client" element={<Client />} />  {/* Lowercase path for Client */}
        <Route path="/about-us" element={<View />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
