import React, { useState, useEffect } from 'react';
import './Home.css';

const bannerContent = [
  {
    title: "Modernization Application Development",
    description: "Improve your search engine rankings and drive organic traffic to your website",
    image: "https://as2.ftcdn.net/v2/jpg/05/95/70/55/1000_F_595705590_4RPt9XSVTlp0wrBov5rdeCsqvi2ohZzm.jpg",
  },
    {
      title: "Mobile Application Development",
      description: "Improve your search engine rankings and drive organic traffic to your website",
      image: "https://wallpapercave.com/wp/wp9517048.jpg",
    },
  {
    title: "Web UI/UX Development",
    description: "Develop a cohesive brand identity that resonates with your target audience and sets you apart.",
    image: "https://t4.ftcdn.net/jpg/07/89/31/31/240_F_789313189_bRtpvJUdZLg1tghGE12UbjA5TnYUm7RG.jpg"
  },
{
  title: "STAFFING AND CONSULTING SERVICES",
  description: "Find the perfect talent for your organization with our specialized staffing services.",
  image: "https://chandigarhupdates.com/wp-content/uploads/2021/08/IT-Companies-In-Panchkula.png"
},
  {
    title: "Big Data Solution",
    description: "Craft intuitive user interfaces and seamless experiences that delight your customers.",
    image: "https://cdn.pixabay.com/photo/2020/02/03/20/49/technology-4816658_1280.jpg"
  },
  {
    title: "Custom Application Development",
    description: "Manage and grow your social media presence to build community and increase brand loyalty.",
    image: "https://cdn.pixabay.com/photo/2024/09/08/13/46/ai-generated-9032231_1280.jpg"
  }
];

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % bannerContent.length);
    }, 3000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % bannerContent.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + bannerContent.length) % bannerContent.length);
  };

  return (
    <section className="home" id="home">
      <div className="banner-slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {bannerContent.map((content, index) => (
          <div
            key={index}
            className={`banner-slide ${index === currentSlide ? 'active' : ''}`}
          >
            <div className="slide-image" style={{ backgroundImage: `url(${content.image})` }}></div>
            <div className="slide-content">
              <h2>{content.title}</h2>
              <p>{content.description}</p>
              {/* <button className="cta-button">Learn More</button> */}
            </div>
          </div>
        ))}
      </div>
      <button className="slider-nav prev" onClick={prevSlide}>&lt;</button>
      <button className="slider-nav next" onClick={nextSlide}>&gt;</button>
      <div className="slider-dots">
        {bannerContent.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default Home;
