import React from 'react';
import './IndustryDetail.css';
import './Industries.css'
import Header from './Header';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai'; 


const IndustryDetail = ({ industry }) => {
 
  // Logic to fetch or display industry-specific information based on the industry prop
  const renderContent = () => {
    
    switch (industry.toLowerCase()) {
      case 'bfsi':
        return (
          <div className="bfs-content" id="industry-bfsi">
            <Header/>
            <h1>BFSI</h1>
            <h2>Banking, Financial Services, and Insurance (BFSI)</h2>
            <p>
              This industry landscape has been undergoing transition at a rapid pace. Pressures of customer retention, product and channel profitability, risk management, and improving financial performance have compelled financial institutions to aggressively implement a range of IT solutions ensuring customer satisfaction.
            </p>
            <p>
              Customers (retail and corporate) have become increasingly discerning in all respects. Various digital channels like mobile, IVR, browser, and ATM have become very important since 24/7 availability of information is a must. With our content framework, financial and non-financial information is distributed through these digital channels seamlessly and securely.
            </p>
            <h3>Our Achievements:</h3>
            <ul>
              <li>Financial solution to enable all bank customers to apply for public issues through stock exchange.</li>
              <li>Loan portfolio for home/personal/commercial on internet and mobile banking.</li>
              <li>Internet, mobile, and SMS banking.</li>
              <li>BI/DW solutions for BFSI clients to enable them to take informed and accurate decisions.</li>
            </ul>
            <h3>Our Services:</h3>
            <p>Our team is adept at providing banking solutions for BFSI in:</p>
            <ul>
              <li>Internet banking</li>
              <li>Mobile and SMS banking</li>
              <li>Public issues for IPOs, rights, mutual funds</li>
              <li>Data warehousing solutions</li>
            </ul>
            <h3>Create business value with our solutions</h3>
            <p>
              Our team of skilled professionals has worked with several financial institutions to simplify complex banking procedures and deploy solutions that can turn heterogeneous data into actionable intelligence.
            </p>
          </div>
        );
      case 'technology':
        return (
          <div className="technology-content" id="industries-technology">
            <Header/>
            <h1>Technology</h1>
            <p>
              The arena of the technology industry is changing progressively. Therefore, technology companies have to update their working methodologies and business models and processes. They have to adapt themselves and stay responsive to complex customer demands.
            </p>
            <p>
              Our experts help technology companies manage their processes in accordance with the changing technology trends. Our technology consultants help companies to update their internal processes and improve their solutions and products. We provide tailored solutions that meet the needs of clients.
            </p>
            <p>
              We have a flexible business and engagement model that makes us an ideal partner for many technology companies. We have successfully provided a diverse range of functional and technical services to various clients all over the globe.
            </p>
            <h3>Our Services:</h3>
            <ul>
              <li>Technology Consulting Services</li>
              <li>Mobility Solutions</li>
              <li>Big Data / BI Analytics and Information Management</li>
              <li>Software and Web Development</li>
              <li>Product Engineering</li>
              <li>System Integration</li>
            </ul>
            <h3>Why Info Way Solutions?</h3>
            <ul>
              <li>
                <strong>Reduce operational costs:</strong> Our technology services help to cut down the technology expenses spent on operations. We provide solutions to every department in an organization to manage operations by implementing robust technology solutions.
              </li>
              <li>
                <strong>Better communication in departments:</strong> Lack of proper communication can make matters worse. We provide a single solution to streamline operations and manage information uniformly. This promotes proper communication, appropriate and updated information sharing, and project collaboration.
              </li>
              <li>
                <strong>Increase in productivity:</strong> Accurate usage of technology tools helps to complete the work prior to the stipulated time. We increase the efficiency of every resource by providing handy tools to manage day-to-day operations.
              </li>
            </ul>
          </div>
        );
        case 'aerospace':
        return (
            <div className="aerospace-defense">
               <Header/>
              <h1>Aerospace and Defense</h1>
              <p>
                The Aerospace and Defense industry is comprised of companies that manufacture spacecraft, aircraft, defense & security, and information systems. It is in constant demand of highly trained technical, engineering, and IT professionals. The Aerospace and Defense industry is concentrated and has fueled incredible growth in recent years.
              </p>
              <p>
                At Info Way Solution, we produce comprehensive and efficient solutions for our customers that drive overall growth. Our in-depth understanding of the Aerospace and Defense industry and world-class recruitment strategies create high-impact business value in a cost-effective manner. Aerospace and Defense is an integral part of Info Way Solution's staffing services, and we are continuously committed to providing exceptional services that add value to our customers' business.
              </p>
              <p>
                Info Way Solution has the professional and technical expertise to employ qualified talent in your organization based on your requirements. As a reputed provider of IT and Technical staffing, Info Way Solution creates a flexible platform of engagement between customers and candidates with an end-to-end and seamless recruitment process. We build tailored recruitment strategies specific to our customers' challenges and goals, reducing their workload and helping them increase focus on core business functions.
              </p>
              
              <h3>We offer the following solutions for the Aerospace and Defense industry:</h3>
              <ul>
                <li>Information & Cyber Security (IA, LIVEWIRE, Cryptographic programs, Multilevel security support, EKMS support, Intelligence Reconnaissance and Surveillance)</li>
                <li>Missile Defense Systems & Testing (AEHF, SM-3 Block IIA, and SM-6)</li>
                <li>Fighter and Transport Aircraft (Apache, Blackhawk, F15, F-35, F/A 18, F117, C130, JSB, C17, B2, CH-47 Chinook, V-22 Osprey, P3)</li>
                <li>Satellite (AEHF, MILSTAR, TIROS, PAN, EchoStar, MBSAT, Galaxy, MUOS, GOES/R, ViaSat, TerreStar)</li>
                <li>Space Exploration – Human Space Flight (ISS, ORION/MPCV, MARS Rover)</li>
              </ul>
        
              <h3>Why Info Way Solution?</h3>
              <ul>
                <li>Complete control over recruitment processes</li>
                <li>Optimized supply chain</li>
                <li>Valuable business insights and reports</li>
                <li>Single point of contact</li>
                <li>Cost-effective solutions</li>
              </ul>
            </div>
          );
      case 'healthcare':
        return (
          <div className="healthcare-content" id="industry-health">
             <Header/>
            <h1>Healthcare</h1>
            <p>
              We offer solutions to maintain patient data securely in a cost-effective manner. Our BI solutions provide a wider and safer access to clinical data, which enables healthcare professionals to make sound operational and clinical decisions. This results in streamlining and offering exceptional healthcare experiences to the patients. The BI platform not only helps healthcare professionals access enterprise-wide data but also facilitates data conversion for report and predictive analysis.
            </p>
            <h3>Our Services:</h3>
            <ul>
              <li>Application support</li>
              <li>Revenue Cycle Optimization</li>
              <li>Situational Analysis</li>
              <li>Expectation Surveys</li>
              <li>Problem Solving</li>
              <li>Balanced Scorecards</li>
              <li>Performance Measuring System</li>
              <li>Predictive Modelling</li>
            </ul>
            <h3>Why Info Way Solutions?</h3>
            <ul>
              <li>
                <strong>Save costs:</strong> Our solutions help to optimize revenue cycle optimization by preventing denial of claims.
              </li>
              <li>
                <strong>Improve margins:</strong> By implementing a BI/balanced scorecard, we help our customers improve their margin from 2%-10% each year.
              </li>
              <li>
                <strong>Improve patient satisfaction:</strong> By providing comprehensive reporting and analytical tools, we help customers improve their patient satisfaction by 15%-30%.
              </li>
              <li>
                <strong>Enhance patient care:</strong> By utilizing point-of-care information, we enable customers to identify high-risk and non-compliant patient solutions and improve patient outcomes.
              </li>
            </ul>
            <p>
              Performance management and data management play an important role in the operations sector of any company. We provide the tools and services required to manage these two important factors and also integrate them with our BI services. Our BI services have carved a unique niche in the telecom and health domain, assuring you to enable your company to adapt to continuously changing customer requirements and market trends.
            </p>
          </div>
        );
      case 'manufacturing':
        return (
          <div className="manufacturing-content" id="industry-manufacturing">
             <Header/>
            <h1>Manufacturing</h1>
            <p>
              We have the domain experience and the technological depth to address a range of challenges that the manufacturing industries face. Our team of experts has helped many leading global manufacturing companies leverage IT to optimize their production processes and integrate operational requirements with enterprise-level decision-making.
            </p>
            <p>
              To maintain a competitive edge in this increasingly complex environment, manufacturers today have to look beyond traditional means towards creating new value chains which encourage collaboration, reduce overheads and liabilities, expedite products to market, and understand which products their targeted markets desire.
            </p>
            <h3>Our Expertise:</h3>
            <p>Info Way Solutions specializes in SAP solutions and provides ERP consulting and services to progressive manufacturers thereby acquainting them with:</p>
            <ul>
              <li>Automated solutions to achieve exponential savings and improve profit margins</li>
              <li>Globally competent solutions to sustain competitive edge and increase market share</li>
              <li>Effective information system to increase manufacturing performance</li>
              <li>Our expertise in manufacturing solutions which reduce the time to market and help to outperform the competition</li>
            </ul>
            <h3>Our Manufacturing Solutions Include:</h3>
            <ul>
              <li>Integrated manufacturing solution</li>
              <li>Innovative supply chain solution</li>
              <li>Enterprise integration solution</li>
              <li>Enterprise resource planning solution</li>
              <li>Portal solution</li>
              <li>EDI integration</li>
              <li>Engineering design services</li>
            </ul>
          </div>
        );
      case 'life-sciences':
        return (
          <div className="life-sciences-container" id="industry-life-sciences">
             <Header/>
            <h1>Life Sciences</h1>
            <p>
              The Life Sciences industry is an evergreen sector as it rarely faces the recession blues. In the last few years, this sector has grown by leaps and bounds.
            </p>

            <h2>Challenges Faced</h2>
            <ul>
              <li>Keeping pace with changing government policies and regulations</li>
              <li>Stringent processes for quality and manufacture compliance</li>
              <li>Rising costs for R&D</li>
              <li>Tracking patent processes</li>
            </ul>

            <h2>Our Solutions for the Pharmaceutical Industry</h2>
            <ul>
              <li>Master Data Management</li>
              <li>BI and Reporting Service</li>
              <li>Mobility Solution for Marketing</li>
              <li>Customer Relationship Management</li>
              <li>Digital Asset Management</li>
              <li>Integrated Data Solutions</li>
              <li>Supply Chain and Demand Planning</li>
              <li>ERP and SAP solutions</li>
            </ul>
            <h3>Why Info Way Solutions?</h3>
            <ul>
              <li>
                <strong>Quality at the Core:</strong> Our focus on quality means that we prioritize excellence at every stage of your project, ensuring compliance with industry standards and regulations.
              </li>
              <li>
                <strong>Agility and Flexibility:</strong> We understand the importance of adapting to changes in the industry. Our solutions are designed to be flexible, allowing your organization to pivot as needed.
              </li>
              <li>
                <strong>Data-Driven Insights:</strong> Leverage our analytics and reporting solutions to make informed decisions that drive your business forward.
              </li>
            </ul>
            <h3>Future-Ready Solutions</h3>
            <p>
              As the life sciences sector evolves, our commitment to innovation ensures that you stay ahead of the curve. We provide cutting-edge solutions tailored to the unique challenges of the industry.
            </p>
          </div>
        );
        case'telecom':
        return (
            <div className="telecom" id="industry-telecom">
               <Header/>
              <h1>Telecom</h1>
              <p>
                The demanding needs and preferences of consumers in the telecom industry have led to a faster churn-out of telecom products and services. However, factors like increasing competition, changing regulations, converging business boundaries, time-bound production schedules, and the time to market have prevented vendors from discerning exact client requirements.
              </p>
              <p>
                While the industry has shifted from product-centric to consumer-centric operations, there still exists a gap between analyzing customers’ needs and modifying business processes to meet those unique needs. This scenario is a testament to the premise that Business Intelligence (BI) integrated with traditional Client Relationship Management (CRM) solutions can create substantial profit in the existing CRM environment.
              </p>
              <p>
                Business intelligence (BI) enables companies to make informed decisions based on available or mined data existing in different networks. At Info Way Solution, we assist telecom vendors in managing data revolving around consumers, through comprehensive BI and Data Warehousing (DW) solutions.
              </p>
        
              <h3>We provide the following BI/DW services for the telecom industry:</h3>
              <ul>
                <li>Business Intelligence</li>
                <li>Predictive Analysis</li>
                <li>Adhoc Reporting</li>
              </ul>
        
              <p>Our BI/DW services allow telecom service providers to:</p>
              <ul>
                <li>Locate the latest revenue streams and methods and converge all functions for enhancing client experience</li>
                <li>Assess particular Call Detail Records (CDR) with quantifiable results</li>
                <li>Improve logical operations of the firm for supervising fiscal information and assess business models to create dependence among clients</li>
                <li>Create robust safety measures for clients' fiscal and personal information</li>
                <li>Provide meaningful information on client transactions for the formulation of better service policies</li>
                <li>Provide data on services and distribution along with functional data extracted from all commercial sectors</li>
                <li>Supervise workforce expenditures on customer care and technical support by optimizing individual competence</li>
              </ul>
            </div>
          );
      case 'retail':
        return (
          <div className="retail-content" id="industry-retail">
             <Header/>
            <h1>Retail</h1>
            <p>
              Retail is a dynamic sector driven by ever-changing consumer preferences and technology advancements. We help retail businesses thrive in this competitive landscape by providing tailored solutions that enhance operational efficiency and customer experience.
            </p>
            <h3>Our Retail Solutions:</h3>
            <ul>
              <li>Customer Engagement and Loyalty Programs</li>
              <li>Inventory Management Systems</li>
              <li>Point of Sale (POS) Solutions</li>
              <li>Supply Chain Management</li>
              <li>E-commerce Solutions</li>
            </ul>
            <h3>Why Choose Info Way Solutions?</h3>
            <ul>
              <li>
                <strong>Personalized Customer Experiences:</strong> Our solutions enable retailers to create personalized shopping experiences that keep customers coming back.
              </li>
              <li>
                <strong>Operational Efficiency:</strong> Streamline your operations with our integrated solutions that reduce costs and improve productivity.
              </li>
              <li>
                <strong>Data-Driven Strategies:</strong> Use data analytics to understand customer behavior and optimize inventory, marketing, and sales strategies.
              </li>
            </ul>
          </div>
        );
        case 'travel-hospitality':
            return (
              <div className="travel-hospitality-container" id="industry-travel-hospitality">
                 <Header/>
                      <h2>Travel &amp; Hospitality</h2>
                      <p>
                        Constant evolution of the travel and hospitality industry has revolutionized this segment.
                        With globalization, it has become even more critical to keep pace with the changing transactional,
                        developmental, and operational demands of the customers while achieving the objective of maximizing value.
                        Infoway Solution has the expertise to design and develop technology solutions to cater to the growing
                        demand of consumers. To achieve greater customer sustenance and loyalty, companies need to adopt
                        numerous measures and keep themselves ahead in the competitive environment. Digital technology
                        represents a milestone for the travel &amp; hospitality industry.
                      </p>
                      <p>
                        Technological advancements and changing trends have given a boost to the travel industry. Property websites,
                        email marketing, mobile technology, social, and visual media are some of the transforming factors in the travel
                        &amp; hospitality industry. Infowaygroup offers holistic solutions to enable excellent business performance by
                        providing these solutions and services.
                      </p>
                
                      <h3>Our Travel &amp; Hospitality services</h3>
                      <ul>
                        <li>Website Design and Redesign</li>
                        <li>Website Hosting and Maintenance</li>
                        <li>Business and E-Commerce Solutions</li>
                        <li>Content Management Systems and Web Crawling</li>
                        <li>Business and Enterprise Solutions</li>
                        <li>WAP/Mobile Applications</li>
                      </ul>
                
                      <h3>Why infowaygroup?</h3>
                      <ul>
                        <li>Innovative and efficient use of mobile technology</li>
                        <li>Use of social network on brand image to retain customers, influence travel decisions, and gain new customers</li>
                        <li>Personalization, behavioral marketing, and offer optimization by providing relevant information</li>
                        <li>Reducing launch window conceptualization to deployment for launching new products and services using cloud services</li>
                        <li>Ability to convert social media information into valuable Business Intelligence (BI)</li>
                        <li>Improve revenue management by optimizing pricing models</li>
                        <li>Cloud computing for travel &amp; hospitality segment</li>
                        <li>Multilingual websites</li>
                      </ul>
                    </div>
                  
        );
      default:
        return <p>Please select a valid industry.</p>;
    }
  };
  
  return (
   
    <div className="industry-detail">
      {/* Home icon button at top-left */}
      <div className="top-right-close-icon">
        <Link to="/">
          <AiOutlineClose size={30} />
        </Link>
      </div>


      {renderContent()}

      {/* Back to Home button at the bottom */}
      <div className="bottom-home-button">
        <Link to="/">
          <button className="back-home-btn">Back</button>
        </Link>
      </div>
    </div>
  );
};

export default IndustryDetail;
