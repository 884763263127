import React from 'react';
import './Work.css'; // CSS file for styling
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
const WorkforceDiversity = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className="workforce-diversity">
      <Header/>
         <img 
        src="https://infowaygroup.com/blog/blog_images/workplace_diversification.jpg" 
        alt="Managing Internal Staff" 
        className="four-image"
      />
      <h1 className="workforce-diversity-title">Workforce Diversity</h1>
      <p className="workforce-diversity-intro">
        With the growing globalization of today’s economy, achieving diversity in the workplace is a smart move to make.
      </p>
      <h2 className="workforce-diversity-subtitle">What is Workforce Diversity?</h2>
      <p className="workforce-diversity-text">
        Workforce diversity means similarities and differences among employees in terms of age, cultural background, physical abilities and disabilities, race, religion, gender, and sexual orientation.
      </p>
      <h2 className="workforce-diversity-subtitle">Understanding Customers</h2>
      <p className="workforce-diversity-text">
        The more diverse your workforce is, the more they're likely to understand your customers. By having a better understanding, they can get more work done and keep the customers flowing in. This leads to a productive business that retains customers all the time.
      </p>
      <h2 className="workforce-diversity-subtitle">The Advantages of a Diverse Workforce</h2>
      <p className="workforce-diversity-text">
        Working to create a diverse workforce has numerous advantages for companies. Diverse employees are going to come up with more varied, unique approaches and ideas, allowing a company to better innovate and develop creative solutions. A homogeneous workforce can lead to creative stagnation, which can hinder a company’s success.
      </p>
      <p className="workforce-diversity-text">
        Additionally, employees who speak different languages open up international business opportunities and allow outreach into local communities where other languages are spoken. A diverse workforce also improves a company’s reputation, enabling it to attract better talent and more customers.
      </p>
      <p className="infoway-message">
        We at Infoway Group are a diversified group of people who totally believe in the mantra: <em>"Workforce Diversity Leads To Increased Productivity."</em>
      </p>
      <Link to="/" className="back-button">Back</Link>
    </div>
  );
};

export default WorkforceDiversity;
