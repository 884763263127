import React from 'react';
import { Link } from 'react-router-dom';
import './News.css';
import Header from './Header';

function Insights() {
  return (
    
    <section className="news" id="news">
      <Header/>
      <section className="services" id="services">
        <div className="main-text">
          <h2 className='ei'> Insights</h2>
        </div>

        <div className="services-content">
          <div className="box">
            <div className="row">
              <img src="https://www.infowaygroup.com/blog/blog_images/internal_mange.jpg" alt="Group discussion" />
              <div className="layer">
                <Link to="/Four"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>4 Tips to Help Your Staffing Firm’s Internal Staff Manage The Stress of Implementing New Solutions</span></h3>
            <p>If you are implementing new solutions with your firm, here are some tips to help your internal staff manage the stress.</p>
            <Link to="/Four" className="read">Read More</Link>
          </div>

          <div className="box">
            <div className="row">
              <img src="https://www.infowaygroup.com/blog/blog_images/requirement_app.jpg" alt="Professional discussion" />
              <div className="layer">
                <Link to="/Small"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>Why Small Businesses Should Utilize Recruitment App</span></h3>
            <p>Not only do recruitment apps benefit the businesses, but they are also a great way to make your business look professional and presentable to top applicants...</p>
            <Link to="/Small" className="read">Read More</Link>
          </div>

          {/* Existing Product: Twillo */}
          <div className="box">
            <div className="row">
              <img src="https://infowaygroup.com/blog/blog_images/reading.jpg" alt="Small group discussion" />
              <div className="layer">
                <Link to="/Read"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>Are you reading the right stuff to progress your career?</span></h3>
            <p>What types of things should you be reading to broaden your knowledge on business subjects with an aim of progressing your career opportunities? Update your reading list here!</p>
            <Link to="/Read" className="read">Read More</Link>
          </div>

          {/* Existing Product: ADA AI */}
          <div className="box">
            <div className="row">
              <img src="https://infowaygroup.com/blog/blog_images/work_from_home.jpg" alt="ADA AI" />
              <div className="layer">
                <Link to="/Job"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>Job Cuts or Surge - What Hiring Looks Like Today</span></h3>
            <p>The future of work is being shaped by two powerful forces: The growing adoption of artificial intelligence in the workplace, and the expansion of the workforce to include both on- and off-balance-sheet talent.</p>
            <Link to="/Job" className="read">Read More</Link>
          </div>

          {/* New Product: CRM */}
          <div className="box">
            <div className="row">
              <img src="https://infowaygroup.com/blog/blog_images/workplace_diversification.jpg" alt="CRM" />
              <div className="layer">
                <Link to="/Work"><i className='bx bx-link-external'></i></Link>
              </div>
            </div>
            <h3><span>Workforce Diversity</span></h3>
            <p>Workforce diversity means similarities and differences among employees in terms of age, cultural background, physical abilities and disabilities, race, religion, gender, and sexual orientation.</p>
            <Link to="/Work" className="read">Read More</Link>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Insights;
