import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import './Small.css';
import Header from './Header';

const Small = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="recruitment-app-container">
      <Header/>
      <img 
        src="https://www.infowaygroup.com/blog/blog_images/requirement_app.jpg" 
        alt="Managing Internal Staff" 
        className="four-image"
      />
      <h1 className="recruitment-app-title">Why Small Businesses Should Utilize Recruitment App</h1>
      <p className="recruitment-app-description">
        Hiring and training competent employees is essential for maintaining a successful business. However, finding the right fit quickly can be challenging. Recruitment software helps businesses streamline the hiring process, making it a valuable investment.
      </p>

      <h2 className="section-title">What are Recruitment Softwares?</h2>
      <p className="section-description">
        Recruitment apps, also known as applicant tracking software (ATS), manage applications, emails, candidate information, interview schedules, and other hiring-related data. ATS simplifies filtering the best applicants, saving time and money.
      </p>

      <h2 className="section-title">Traditional Ways Small Businesses Manage Recruitment</h2>
      <p className="section-description">
        Before recruitment software, businesses managed hiring using Google Sheets or Excel. While affordable and simple, these methods are often inefficient for modern hiring needs.
      </p>

      <h2 className="section-title">Hire a Staffing Agency</h2>
      <p className="section-description"><strong>Pros:</strong> Staffing agencies match businesses with potential candidates.</p>
      <p className="section-description"><strong>Cons:</strong> Some resumes may not meet the company’s standards.</p>

      <h2 className="section-title">Google Sheets/Excel</h2>
      <p className="section-description"><strong>Pros:</strong> These are affordable and user-friendly tools for managing recruitment.</p>
      <p className="section-description"><strong>Cons:</strong> They lack advanced sorting and tracking features compared to ATS.</p>

      <h2 className="section-title">Why Do Small Businesses Need Applicant Tracking Software?</h2>
      <p className="section-description">
        Even for small businesses with lower turnover, the hiring process is time-consuming. ATS helps streamline recruitment, making it more efficient.
      </p>

      <div class="back-button-container">
    <a href="/" class="back-button">Back</a>
</div>

    </div>
  );
};

export default Small;
