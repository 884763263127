import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import './View.css';
import Header from './Header';
import { useEffect } from 'react';
const View = () => {
  const navigate = useNavigate();

  // Function to navigate back to home
  const goHome = () => {
    navigate('/');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className="about-us-container">
       <Header/>
      {/* Top-left Home button */}
      <button className="home-button" onClick={goHome}>
        <FontAwesomeIcon icon={faHome} size="2x" />
      </button>

      <img src="https://www.infowaygroup.com/sites/all/themes/infowaygroup/images/new/about.jpg" alt="About Us" />
      <h1>About Us</h1>
      <p>
        Since 2012, Info Way Solutions has earned a reputation as a consulting services provider that excels in a fast-paced marketplace. We have placed highly skilled professionals on a temporary, temporary-to-hire, and direct-hire basis in the fields of accounting and finance, IT technology, office administration, marketing, and design.
      </p>
      <p>
        Info Way Solutions is dedicated to providing you with quality professionals with well-matched skills to your unique hiring requirements and workplace environment. We collaborate with customers to consistently and cost-effectively plan, execute, and deliver high-quality services and results. Our consultants are seasoned professionals who build long-term, trusted relationships that bring about a high level of energy, integrity, experience, and value to our clients’ work.
      </p>
      <p>
        Our superior client-consultant relationships and in-depth insight of services have resulted in significant repeat business with clients. In addition, we are partners with best-in-class technology vendors that enable us to determine the most appropriate hardware, software, and services to cater to any of our clients’ business needs. We have often collaborated with them to make optimal use of today’s technology while also planning for the future.
      </p>
      <p>
        More than 50 customers – including mid-market leaders and many of the Fortune 500 Companies – rely on our expertise to leverage their investments.
      </p>

      <h2>Our Mission</h2>
      <p>
        To remain as the IT consulting firm that is a leader in fulfilling the diverse needs of our customers while never relinquishing our core values.
      </p>

      <h2>Our Vision</h2>
      <p>
        To significantly contribute to the success of our clients’ business by offering cost-effective and quality services that would result in true customer satisfaction as well as a competitive edge.
      </p>

      {/* Bottom "Back to Home" button */}
      <button className="back-to-home-button" onClick={goHome}>
        Back
      </button>
    </div>
  );
};

export default View;
