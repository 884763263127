import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';
import './Twillo.css';
import Header from './Header';

function Twillo() {
    const navigate = useNavigate();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClose = () => {
        navigate('/');  // Navigate back to home page
    };

    return (
        <div className="upreach-container relative">
            {/* Close button */}
            <button 
                onClick={handleClose}
                className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
                aria-label="Close"
            >
                <X className="w-6 h-6 text-gray-600" />
            </button>

            <Header />
           
            <h1 className="upreach-title">Upreach: Advanced Business Messaging</h1>
            {/* Rest of your existing content */}
            <p>
                In today's fast-paced digital world, connecting with customers quickly and efficiently is crucial. 
                With Upreach, our advanced business messaging application, you can reach thousands of customers 
                with ease and maintain a strong connection through two-way communication. 
                Say goodbye to missed messages and hello to streamlined customer engagement!
            </p>

            <h1 className="upreach-features-title">Key Features</h1>
            <ul className="upreach-features">
                <li>
                    <strong>Mass Messaging Made Easy:</strong> Effortlessly send bulk messages to thousands of customers in seconds. 
                    Whether it's promotional updates, important notifications, or personalized messages, Upreach ensures your message reaches the right audience.
                </li>
                {/* Rest of the features list */}
            </ul>

            <h1 className="upreach-why-title">Why Choose Upreach?</h1>
            <ul className="upreach-why">
                <li><strong>Scalable Solution:</strong> Our platform grows with your business, accommodating your needs as your customer base expands.</li>
                <li><strong>User-Friendly Interface:</strong> Intuitive design for quick onboarding and easy use, no matter your tech experience.</li>
                <li><strong>24/7 Customer Support:</strong> Our dedicated support team is always here to help, ensuring a smooth experience from setup to daily use.</li>
            </ul>

            <div className="upreach-get-started">
                <h2>Get Started Today!</h2>
                <p>
                    
                    Unlock the potential of direct communication with Upreach and transform the way you engage with your customers.
                </p>
                <p>
                    Visit our website or contact us to learn more or request a demo. Connect, engage, and grow your business with Upreach!
                </p>
                <Link to="/" className="back-button">Back</Link>
            </div>
        </div>
    );
}

export default Twillo;