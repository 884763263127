import React from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import Header from './Header';
import './ServiceDetail.css';
import {  AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import  { useEffect } from 'react';

const cardContent = [


  
  {
    id: 0,
    title: "Application Modernization",
    description: "Overview of Application Modernization...",
    fullDescription: `
     
            <h2>Why Modernize Your Applications?</h2>
            <p>Applications are the lifeblood of modern businesses. Yet many organizations find themselves burdened by existing legacy applications that can stifle growth and innovation. Application modernization is the process of revitalizing outdated applications to align with current business needs and take advantage of the latest technological advancements.</p>
            <p>Application modernization goes far beyond basic maintenance or upgrades. It represents a fundamental shift in how you approach your legacy applications, transforming them into adaptable, cloud-ready solutions using the latest application modernization technology.</p>
        
            <h2>Challenges of Legacy Applications</h2>
            <p>Here are a few ways legacy applications can hold organizations back:</p>
            <ul>
                <li><strong>Technical debt:</strong> Older systems often accumulate inefficient architectures and complex dependencies.</li>
                <li><strong>Agility constraints:</strong> Monolithic architectures make updates challenging.</li>
                <li><strong>Security risks:</strong> Outdated applications may contain known vulnerabilities.</li>
                <li><strong>Scalability challenges:</strong> Legacy systems struggle to handle increased traffic or new functionality.</li>
                <li><strong>Rising costs:</strong> The upkeep of outdated applications can drain resources.</li>
            </ul>
            <h2>Benefits of Application Modernization</h2>
            <p>Here’s a closer look at the key advantages of upgrading an application to modern standards:</p>
            <ul>
                <li><strong>Enhanced agility:</strong> Modernized applications are designed for rapid change.</li>
                <li><strong>Improved scalability:</strong> Leveraging cloud-native architectures helps handle fluctuations in demand.</li>
                <li><strong>Increased efficiency:</strong> Streamlined workflows and automated tasks free up your team to focus on innovation.</li>
                <li><strong>Greater cost savings:</strong> Optimizing processes can reduce long-term IT expenses.</li>
                <li><strong>Enhanced security:</strong> Modern frameworks allow organizations to fix vulnerabilities and implement advanced security protocols.</li>
            </ul>
       
     
            <h2>Patterns for Modernizing Applications</h2>
            <p>Successful application modernization draws upon several established patterns:</p>
            <ul>
                <li><strong>Replace:</strong> Swap legacy applications with off-the-shelf solutions.</li>
                <li><strong>Retain:</strong> Leave well-functioning applications as is.</li>
                <li><strong>Retire:</strong> Phase out costly legacy applications.</li>
                <li><strong>Rehost:</strong> Move applications to a new infrastructure with minimal code changes.</li>
                <li><strong>Replatform:</strong> Adapt applications to new platforms with limited code changes.</li>
                <li><strong>Rewrite:</strong> Rewrite the entire application from scratch.</li>
                <li><strong>Refactor:</strong> Restructure the codebase to enhance design and performance.</li>
            </ul>
      
        
            <h2>Strategies for Transforming Legacy Systems</h2>
            <p>Implementing a successful application modernization strategy requires careful consideration and execution:</p>
            <ul>
                <li><strong>Start with a thorough assessment:</strong> Analyze your application landscape.</li>
                <li><strong>Define clear goals:</strong> Articulate the reasons behind your modernization project.</li>
                <li><strong>Plan for incremental change:</strong> Break down the process into manageable increments.</li>
                <li><strong>Choose the right technologies:</strong> Evaluate cloud services and modern software frameworks.</li>
                <li><strong>Invest in your people:</strong> Provide training and upskilling for your development team.</li>
                <li><strong>Emphasize security:</strong> Incorporate modern security frameworks from the start.</li>
            </ul>
     
      
            <h2>Essential Technologies for Advancing Application Modernization</h2>
            <p>Here are a few key technologies that can help with modernization efforts:</p>
            <ul>
                <li><strong>Cloud computing:</strong> Provides flexibility and scalability.</li>
                <li><strong>Containers:</strong> Facilitate consistent deployment across environments.</li>
                <li><strong>Microservices:</strong> Improve agility and resilience.</li>
                <li><strong>DevOps Tools:</strong> Increase the speed and reliability of software delivery.</li>
                <li><strong>Cloud-native data management:</strong> Modernize your data storage and management.</li>
                <li><strong>AI and ML:</strong> Integrate AI features for automation and insights.</li>
            </ul>
      
    `,
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqBghz6xCJGliBHRxjIHLiuof9RQSM8uhvrg&s"
  },
  {
    id: 1,
    title: "Big Data",
    description: "We at Infoway provide solutions strategy, engineering, and analytics to help maximize your data insights.",
    fullDescription: `
      <p>Our end-to-end services, matched with industry-specific skills and processes, help to make your data simpler to access and understand, accelerate time to capability, and can improve data-driven business outcomes.</p>
      <p>Organizations, nowadays, face an explosion of data from more sources than ever before. As a result, many companies are altering their approach to traditional enterprise storage and architecture to utilize and strategize with big data technologies.</p>
      <p>Storing and managing these huge pools of information is a challenge for many enterprises. However, big data analytics also offers significant opportunities for intuitive data insights.</p>
      <p>At Infoway, we have the expertise in big data tools and processes to derive actionable insights from mountains of disparate data that enterprises collect each day. Our specialists have pioneered big data analytics solutions for leading organizations around the world and we offer complete services to help you harness the power of your big data.</p>
      <p>Infoway offers the following services to its customers:</p>
      <ul>
          <li>Evolve Big Data Strategy and Big Data maturity roadmap</li>
          <li>Data Analytics advisory</li>
          <li>Industry-tailored frameworks to meet unique domain needs</li>
          <li>‘As-is and To-be’ landscape assessment</li>
      </ul>
      <p>We deliver big data and analytics services to help you strengthen your IT foundation and realize new possibilities that enable accelerated growth. We help organizations to improve operational efficiency and lower risk with enterprise data solutions.</p>
    `,
    image: "https://img.freepik.com/free-vector/big-data-isometric-concept_1284-10905.jpg?t=st=1728918177~exp=1728921777~hmac=70d72acf29456deec69ea314b5d042069a56c2351b08cf14b57230dd72a0a9e6&w=740" 
  },
  // Add other services with unique `id` here
    // Existing service entries...
    
    {
      id: 2,
      title: "Staffing Services",
      description: "We build a bridge between our Clients and Consultants.",
      fullDescription: `
        <p>With today’s fast-paced IT industry, staffing needs of organizations vary depending on business needs, program scope, existing staff workloads, and facility utilization.</p>
        <p>At Infoway, we help our customers with flexible supply of best technical and professional talents aligning with business requirements. We facilitate our clientele to maximize the potential of their technology and facilities with our easily scalable full time on-site and temporary staffing resources.</p>
        <p>We pick the best of the talented and real-time-industry-specific experienced IT consultants on the market who excelled in business, technical, and communication skills.</p>
        <p>We have IT consultants ready to relieve the many demands on your environment, time, and resources. Our recruiting and marketing team has direct or indirect reach to a wide network of technical, sales, finance, and accounting professionals.</p>
        <p>We enable our clients to maximize their bottom line by harvesting maximum benefits with our Staffing Services, allowing them to enjoy the utmost Return On Investment (ROI).</p>
        <p>For further details please reach out to us. Email: <a href="mailto:info@infowaygroup.com">info@infowaygroup.com</a></p>
        
        <h2>Areas of Expertise</h2>
        <h3>Contract Staffing</h3>
        <ul>
          <li>Reduce employee-related costs.</li>
          <li>Fill short-term and long-term needs for qualified talents.</li>
          <li>Support upcoming projects.</li>
          <li>Meet seasonal requirements – increase and decrease workforce.</li>
        </ul>
        
        <h3>Contract-to-Hire Staffing</h3>
        <p>As a benefit from the traditional services, our staffing firm offers the option to hire the employee permanently. You can observe the employee on the job to determine for yourself whether he or she is the right fit for the position. As for the employee, he or she can be hired on a permanent basis once the contract period is complete.</p>
        
        <h3>Direct Hire Staffing</h3>
        <p>Info Way Solutions assumes all responsibility for recruiting and screening the candidates. We do the work and you can focus on your business. Our professional recruiters use a wide range of recruiting resources to find the perfect employee.</p>
        
        <h3>Guaranteed Delivery Program</h3>
        <p>Exceptional connections. Guaranteed. At Info Way Solutions, we do more than make claims. We make guarantees. We meet with you to specify the resources required, skill sets, work environment, and project details.</p>
        <p>We agree on mutually acceptable terms. We supply only the most rigorously vetted and highly qualified talent appropriate for the position. You evaluate our candidate for 40 full hours. If you’re not completely satisfied, you pay nothing and it’s our responsibility to find an alternate candidate that meets your expectations.</p>
        <p>It’s that simple – we guarantee your complete satisfaction in 40 hours or you pay nothing. Your risk is eliminated. Your productivity soars. Your results are realized. That’s our promise and we stand behind your success all the way!</p>
      `,
      image: "https://img.freepik.com/free-photo/close-up-businesspeople-working-with-documents_1098-1263.jpg" 
    },
    {
    id: 3,
      title: "Web UI/UX Development",
      description: "We deliver design solutions that are creative.",
      fullDescription: `
     
   
    <p>
      At Infoway, we provide engaging UX with our interactive, responsive, and rich UI design. Our creative UI & UX development team works with you to transform your ideas into meaningful user stories.
    </p>
    
    <p>
      We deliver design solutions that are creative, empowering, brand-centric, and future-ready by leveraging the latest design concepts & frameworks like Material Design, Less, Bootstrap, HTML5, JS, and jQuery.
    </p>

    <p>
      With our web and mobile application design service, we help you visualize the entire software application before it goes into development.
    </p>

    <!-- Service Offering Section -->
    
      <h2>Our Service Offering Includes:</h2>
      <ul>
        <li>Information architecture and prototyping</li>
        <li>Wireframing and Storyboards</li>
        <li>Web and mobile application design</li>
        <li>Graphic design</li>
        <li>Responsive & progressive website development</li>
      </ul>
   
 
         `,
         image: "https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149052117.jpg" 
    },
    {
    id: 4,
      title: "Cyber Security Solutions",
      description: "We deliver design solutions that are creative.",
      fullDescription: `
      
 
   
    <p>
      In the current digital arena, where desired information is available at one’s fingertips and accessible from anywhere at any time, data privacy and protection stand at great importance.
    </p>
    
    <p>
      As the threat landscape evolves with escalating speed, it takes smarter cyber security services to successfully protect your organization. With the right combination of cyber security services and information security technology, you can operate more successfully in a world where everything is increasingly linked together.
    </p>

    <p>
      We at Infoway provide next-generation cyber security solutions encompassing a holistic approach—right from detection to protection, prevention, and remediation of your IT systems.
    </p>

    <!-- Service Offerings Section -->
   
      <h2>Our Cyber Security Service Offerings Include:</h2>
      <ul>
        <li>Penetration test and vulnerability assessment</li>
        <li>Managed Security services</li>
        <li>Security Risk assessments</li>
        <li>Incident Response services</li>
        <li>Cyber Security Training</li>
        <li>Enabling DevSecOps</li>
      </ul>
   

    <!-- DevSecOps Section -->
    
      <h2>Enabling DevSecOps</h2>
      <p>
        Our DevSecOps service ensures security with speed. We extend the "shift left" principle to security as well, and security is introduced early in the development process to help deploy secure code continuously, making overall software secure and compliant.
      </p>

      <p>
        We help define how operations, engineering, and security can work together, collaborating to build secure software applications and products.
      </p>
    

 
         `,
         image: "https://media.istockphoto.com/id/1412282189/photo/lock-network-technology-concept.jpg?s=612x612&w=0&k=20&c=hripuxLs9pS_7Ln6YWQR-Ow2_-BU5RdQ4vOY8s1q1iQ=" 
    },
    {
    id: 5,
    title: "Mobile Application Development",
    description: "We deliver design solutions that are creative.",
    fullDescription: `

  
    <h1>iOS Application Development</h1>
    <p>
      At Infoway, we offer you prominent and hassle-free iOS iPhone & iPad app development services using effective problem-solving solutions.
    </p>
    
    <p>
      Our development process incorporates proven and cutting-edge methodologies and techniques, helping us remain one of the top iOS application development companies.
    </p>

    <p>
      Our iOS experts and development team have the capabilities to manage multi-threaded environments, build advanced algorithms, and provide architectural & technical leadership in designing and developing your core applications.
    </p>

    <!-- iOS Service Offerings -->
    <h2>Our Service Offerings Include:</h2>
    <ul>
      <li>iOS App Development</li>
      <li>Custom iPhone Application Development</li>
      <li>iOS App Design Solution</li>
      <li>iOS App Testing and Porting</li>
    </ul>
 

  <!-- Android Application Development Section -->
  
    <h1>Android Application Development</h1>
    <p>
      Android is one of the fastest-growing mobile operating systems with 60-80% user adoption rates across the globe. Backed by Google, Android is constantly updating and innovating to increase user engagement and smoothen mobile operations.
    </p>

    <p>
      At Infoway, we offer scalable and cloud-ready Android application development. We have executed Android applications ranging from small utility apps to enterprise-grade Android solutions.
    </p>

    <!-- Android Service Offerings -->
    <h2>Our Service Offerings Include:</h2>
    <ul>
      <li>Custom Android Applications Development</li>
      <li>Android UX & Design</li>
      <li>Android App Testing and Porting</li>
    </ul>
  

  <!-- Clients and Testimonials Section -->
 
    <h2>Clients</h2>
    <p>Agilant</p>
    
    <h2>Testimonial</h2>
    <p>
      Info Way Solutions has earned a reputation as an IT consulting firm that excels in a fast-paced marketplace. We have placed highly skilled professionals on a temporary, temporary-to-hire, and direct-hire basis in the fields of accounting and finance, IT technology, office administration, marketing, and design.
    </p>
         `,
         image: "https://blog.webhopers.com/wp-content/uploads/2022/03/Top-Mobile-App-Development-Companies-in-Chennai-1.png" 
    },
    {
      id: 6,
      title: "Robotic Process Automation (RPA)",
      description: "We deliver design solutions that are creative.",
      fullDescription: `
    <p>
      Enterprises across sectors are reinventing themselves by adopting automation solutions that drive innovation, improve productivity, and deliver superior user experiences.
    </p>

    <p>
      At Infoway, we provide RPA solutions together with a unique continuous improvement methodology that simplifies routine and repetitive processes for clients. This includes automating labor-intensive and error-prone back-office tasks like data entry, account creation, and data processing.
    </p>

    <p>
      Our solutions leverage cutting-edge technologies such as cognitive automation, machine learning, and robotic process automation (RPA) to elevate business value and provide a competitive edge.
    </p>

    <p>
      We support tools like Automation Anywhere, Blue Prism, UI Path, Work Fusion, Open Span, as well as proprietary engines. With our knowledge of legacy systems and experience in business process management, we improve process efficiency and reduce human intervention.
    </p>

    <!-- RPA Service Offerings -->
    <h2>Our RPA Service Offerings Include:</h2>
    <ul>
      <li><strong>IT Operations Automations:</strong> Automate repetitive and routine IT tasks to reduce downtime and improve incident management processes.</li>
      <li><strong>IT Services Management Automations:</strong> Automate L1 and L2 support services that handle repetitive, manual processes.</li>
      <li><strong>Business Process Automations:</strong> Optimize various business workflows through RPA technology.</li>
      <li><strong>Enterprise Security Operations Automations:</strong> Automate detection of security threats across enterprise systems, send alerts, eliminate potential risks, and test security systems periodically to ensure they are up to date and functional.</li>
    </ul>

           `,
           image: "https://img.freepik.com/premium-photo/robotic-process-automation-concept-innovation-technology-futuristic-background_1280488-1803.jpg" 
      },
      {
        id: 7,
        title: "Custom Application Development",
        description: "We deliver design solutions that are creative.",
        fullDescription: `
    <p>
      Infoway provides a comprehensive range of custom application development services that enable enterprises to exploit the power of custom applications to support new capabilities and enhance the customer and partner experience.
    </p>
    <p>
      Our deep domain knowledge allows us to develop scalable and flexible application architecture incorporating business logics, presenting our clients with next-generation applications that generate greater efficiency through high levels of automation.
    </p>
    <p>
      Our solutions include providing complete end-to-end application development services across a number of engagement models needed to support the conceptualization, development, deployment, administration, maintenance, and enhancement of IT systems.
    </p>
    <p>
      We provide a mature and robust QA assurance system. The system supports a variety of application life cycle models and helps to support and extend the quality practices of our customers.
    </p>
    <p>
      We support a diverse technology landscape from modern application platforms such as Azure and iOS to traditional legacy platforms such as IBM and HP mainframes.
    </p>

    <!-- Custom Application Development Services -->
    <h2>Our Custom Application Development Services Include:</h2>
    <ul>
      <li><strong>Application Development</strong></li>
      <li><strong>Software Product Development</strong></li>
      <li><strong>Maintenance and support of existing Application</strong></li>
      <li><strong>Enterprise Application Integration</strong></li>
      <li><strong>Analysis and Design Support</strong></li>
      <li><strong>Project Management and Project Planning</strong></li>
      <li><strong>Testing Service</strong></li>
      <li><strong>Upgrades and Optimizations</strong></li>
      <li><strong>Product Help Desk</strong></li>
      <li><strong>Post Deployment Support</strong></li>
    </ul>
  </section>
      
             `,
             image: "https://www.eitbiz.com/blog/wp-content/uploads/2022/05/Software-Development-min.webp" 
        },
    
  
  ];
  


  function ServiceDetail() {

    useEffect(() => {
      // Scroll to top when the component is rendered
      window.scrollTo(0, 0);
    }, []); 

    const { id } = useParams();
    const service = cardContent.find(service => service.id === parseInt(id));
    const navigate = useNavigate();
    if (!service) {
      return <h2>Service not found</h2>;
    }
    return (
      <div className="service-detail-container">
        {/* Close button with icon at the top-right */}
        <div className="top-right-close-icon">
          <Link to="/">
            <AiOutlineClose size={30} />
          </Link>
        </div>
  
        <Header />
  
        <div className="service-detail-header">
          <img src={service.image} alt={service.title} className="service-image" />
          <h1 className="service-title">{service.title}</h1>
        </div>
  
        <div className="service-detail-content">
          <div
            className="service-description"
            dangerouslySetInnerHTML={{ __html: service.fullDescription }}
          />
        </div>
  
        {/* Back to Home button at the bottom */}
        <div className="bottom-home-button">
          <Link to="/">
            <button className="back-home-btn">Back</button>
          </Link>
        </div>
      </div>
    );
  };
  
  export default ServiceDetail;
