import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Lead.css';
import Header from './Header';

const Lead = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Close button handler
  const handleClose = () => {
    navigate('/'); // Replace with your desired navigation action
  };

  return (
    <div className="founder-profile">
      <Header />

      {/* Close icon */}
      <button className="close-buttonf" onClick={handleClose}>
        &times; {/* Or you can use an actual icon here */}
      </button>

      <img 
        src="https://infowaygroup.com/sites/all/themes/infowaygroup/images/new/Kismat%20Kathrani.jpg" 
        alt="Kismat Kathrani" 
        style={{ width: '300px', height: 'auto' }} 
      />

      <h1>Kismat Kathrani</h1>
      <h2>Founder and President at Info Way Solutions LLC</h2>
      <p>
        As founder and President of Infoway Solution, Kismat has been recognized as a highly motivated and focused thought leader in the talent and staffing industry. Clients consistently appreciate his dedication to delivering outside-the-box custom solutions to meet any challenge.
      </p>
      <p>
        Kismat’s eye for talent and his deep-seated faith in empowerment and accountability has helped Infoway grow into an employee-driven organization where each team member values autonomy and respects the responsibility that comes with it.
      </p>
      <p>
        Kismat holds a Bachelor’s in Production Engineering from Poona University and a Master of Business Administration from Poona University.
      </p>

      <p>
        <a 
          href="https://www.linkedin.com/in/kismatkathrani/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="linkedin-link"
          style={{ display: 'inline-flex', alignItems: 'center' }}
        >
          <img 
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbb0_npkny3YOvY91wF6jgOkRwTEcaUcyUOg&s"
            alt="LinkedIn Logo" 
            style={{ width: '60px', height: '60px', marginRight: '10px' }} 
          />
          <span>Connect with Kismat on LinkedIn</span>
        </a>
      </p>
      <div className="back-button-container">
        <Link to="/lead" className="back-button">Back</Link>
      </div>
    </div>
  );
}

export default Lead;
