import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import './Job.css'; // Import the CSS file

const Job = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <Header />
      <img 
        src="https://infowaygroup.com/blog/blog_images/work_from_home.jpg" 
        alt="Managing Internal Staff" 
        className="four-image"
      />
      <h1 className="heading">Job Cuts or Surge - What Hiring Looks Like Today</h1>
      <p className="paragraph">
        The future of work is being shaped by two powerful forces: The growing adoption of artificial intelligence in the workplace, and the expansion of the workforce to include both on- and off-balance-sheet talent.
      </p>
      <p className="paragraph">
        Accelerated by the pandemic, the future of work is here. As increasing connectivity, robotics, and cognitive tools change the nature of work, new talent models and the gig economy are reinventing jobs. Now is the time to reimagine why, where, and how work gets done—and unleash the energy and endless possibilities of human potential.
      </p>
      <p className="paragraph">
        According to a Fortune survey, 59% of CEOs enacted hiring freezes during the pandemic. But the situation is now changing. Wouldn’t you be interested to know what do the hiring trends look like post-pandemic?
      </p>
      <p className="paragraph">
        As the world starts to collectively rebuild from the chaos that was in 2020, we’re amidst a hiring surge now. The hiring freezes, together with the rising demand for unfilled positions have led to an uncharacteristically high volume of job openings littered across top job boards like LinkedIn, which has over 5 million open positions, and Indeed, that recorded approximately 1 million job postings. Additionally, in March 2021, U.S. employers added 916,000 jobs across all sectors, including more than 16,000 new U.S. tech sector jobs created at the same time.
      </p>
      <h2 className="subheading">Boost to Remote Working</h2>
      <p className="paragraph">
        The World Economic Forum estimates that the number of remote workers is set to double in 2021. And the leaders at some of the world’s largest organizations echo this sentiment, like Facebook CEO, Mark Zuckerberg, saying his company would “aggressively” speed up remote hiring this year.
      </p>
      <p className="paragraph">
        Another recent Gartner poll indicated that 48% of employees will likely work remotely at least part of the time after COVID-19 as opposed to 30% pre-pandemic. Organizations shifting to more remote operations have warranted exploring the critical competencies employees will need to collaborate digitally and be prepared to adjust employee experience strategies.
      </p>
      <h2 className="subheading">Building a Diverse & Inclusive Workforce</h2>
      <p className="paragraph">
        Not only has a remote working world accelerated globalization, productivity, and employee happiness, but it has also helped organizations achieve diversity, equity, and inclusion at the workplace. According to a survey by The Consumer Technology Association, 86% of tech companies “have at least one current or planned diversity and inclusion initiation at their organization.”
      </p>
      <h2 className="subheading">Revision of Employee Benefits</h2>
      <p className="paragraph">
        Organizations that leverage contract talent located outside of an HQ city, save money on health insurance contributions, visa fees, salaries, tools and supplies, technology, gratuity benefits, and more. The pandemic has forced employers to act. In the wake of the pandemic, employees’ health and well-being have suffered the most.
      </p>
      <h2 className="subheading">Rise of Nearshore, Offshore, and Onshore Hiring</h2>
      <p className="paragraph">
        To strategically fill the skill gaps across various industries and roles, enterprises are moving toward nearshore and offshore outsourcing to focus on core competencies, and hire high-quality experts, while also reducing operating costs.
      </p>
      <p className="paragraph">
        In the post-pandemic world, organizations look to the future of work with optimism, propelled by human resourcefulness and resilience. At Infoway Group, we’re ready to help you humanize the future of work, elevate your workforce, and foster the environment needed to realize their full potential and the businesses’ desired outcomes.
      </p>
      <Link to="/" className="back-button">Back</Link>
    </div>
  );
};

export default Job;
