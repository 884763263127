import React from 'react';
import { Link } from 'react-router-dom';
import './Ada.css'; // Create this CSS file for styling
import Header from './Header';
import { useEffect } from 'react';

function AdaAI() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className="ada-container">
      <Header/>
      
      {/* Close icon added */}
      <Link to="/" className="close-icon">
        <img 
          src="https://cdn-icons-png.flaticon.com/512/1828/1828778.png" // Replace with your close icon URL
          alt="Close"
          className="close-icon-img"
        />
      </Link>

      <h1 className="ada-title">ADA AI</h1>
      <p className="ada-intro">
        Arcus Digital Assistant delivers a complete solution to create conversational 
        experiences for business applications through voice interfaces.
      </p>
      <p className="ada-date">Sep 28, 2024</p>
      
      <h2 className="ada-subtitle">ADA AI: A Government's Best Friend</h2>
      
      <h3 className="ada-section-title">Problem Statement</h3>
      <p className="ada-problem">
        Large states like California or Texas spend anywhere between 
        <br />
        ➔ <strong>$50 million and $100 million</strong> annually on customer service 
        operations, especially during high-demand periods (e.g., 
        unemployment claims surges).
        <br />
        ➔ Smaller states might spend less, in the range of <strong>$10 million to $30 million</strong> annually.
      </p>
      <p>
        Agencies are struggling to meet the increasing demands of citizens for efficient 
        and timely customer service, often leading to long wait times, frustration, and 
        decreased satisfaction.
      </p>

      <h3 className="ada-section-title">Challenges</h3>
      <ul className="ada-challenges">
        <li>➔ Overwhelming call volumes: Especially during peak periods, such as unemployment claims surges or natural disasters.</li>
        <li>➔ Understaffed call centers: Inability to adequately handle the influx of inquiries.</li>
        <li>➔ Inefficient processes: Manual, time-consuming tasks that slow down response times.</li>
        <li>➔ High operational costs: Maintaining large call centers and human agents can be expensive.</li>
      </ul>
      <p>
        This leads to dissatisfied citizens, increased operational costs, and ultimately, negative public perception.
      </p>

      <h3 className="ada-section-title">Solution</h3>
      <p>
        Therefore, there is a pressing need for novel innovative solutions to improve government 
        customer service, reduce costs, and enhance citizen satisfaction.
      </p>
      <p>
        ADA can cut the cost per interaction by as much as <strong>60-80%</strong>. For example, 
        using voice bots might reduce the cost per call from <strong>$5-$10</strong> to as low 
        as <strong>$1-$2</strong> for basic inquiries.
      </p>
      <p>
        ADA can learn from customer interactions and improvise, providing metrics to measure: 
        improving efficiency, cost reductions, and finally enhancing citizen satisfaction. 
      </p>

      <h3 className="ada-section-title">What is ADA?</h3>
      <p>
        Arcus Digital Assistant (ADA) is a Gen AI driven, state-of-the-art, comprehensive platform for 
        building conversational interfaces for humanely equivalent conversations available 24/7.
      </p>

      <h3 className="ada-platform-features">Gen AI driven, Self-learning, Self-improvising & Reporting Platform</h3>
      <ul className="ada-features">
        <li>Efficiency and Accessibility
          <ul>
            <li>★ 24/7 Availability</li>
            <li>★ Simultaneous Handling</li>
            <li>★ Device Independence</li>
          </ul>
        </li>
        <li>Human Agent Support & Scalability
          <ul>
            <li>★ Reduced Burden</li>
            <li>★ Enhanced Expertise</li>
            <li>★ Scalability</li>
            <li>★ Cost Savings</li>
          </ul>
        </li>
        <li>Consistency and Accuracy
          <ul>
            <li>★ Consistent Responses</li>
            <li>★ Learning and Improvement</li>
            <li>★ Reporting</li>
          </ul>
        </li>
      </ul>

      <div className="back-button-container"> 
        <Link to="/" className="back-button">Back</Link>
      </div>
    </div>
  );
}

export default AdaAI;
