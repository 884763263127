import React from 'react';
import './Services.css';
import { Link } from "react-router-dom";

function Services() {
  
  const cardContent = [
    {
      title: "Application Modernization",
      description: "Modernize your applications faster to accelerate innovation and deliver improved customer experiences.",
      image: "https://t3.ftcdn.net/jpg/08/41/46/46/240_F_841464641_NCiHGo1ZodkiVrf058EXlqrDxHEkZWym.jpg"
    },
    {
      title: "Big Data Solution",
      description: "Craft intuitive user interfaces and seamless experiences that delight your customers.",
      image: "https://www.smartsight.in/wp-content/uploads/2019/06/big-data.png"
    },
    {
      title: "Staffing Services",
      description: "In today's fast-paced IT industry, staffing needs constantly shift with business demands.",
      image: "https://img.freepik.com/free-photo/close-up-businesspeople-working-with-documents_1098-1263.jpg"
    },
    {
      title: "Web UI/UX Development",
      description: "Develop a cohesive brand identity that resonates with your target audience and sets you apart.",
      image: "https://png.pngtree.com/thumb_back/fh260/background/20210914/pngtree-ui-ux-development-design-background-concept-image_900165.jpg"
    },
    {
      title: "Cyber Security Solution",
      description: "Implement comprehensive digital marketing strategies to boost your online presence and reach.",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlNr47_iY45xmoUUF6DNcBGHAvFyF-daeWkA&s"
    },
    {
      title: "Mobile Application Development",
      description: "Improve your search engine rankings and drive organic traffic to your website.",
      image: "https://www.simplilearn.com/ice9/free_resources_article_thumb/Building-a-career-in-Mobile-App-Development.jpg"
    },
    {
      title: "Robotic Process Automation",
      description: "Produce engaging, high-quality content that tells your brand story and connects with your audience.",
      image: "https://media.istockphoto.com/id/1287582736/photo/robot-humanoid-use-laptop-and-sit-at-table-for-big-data-analytic.jpg?s=612x612&w=0&k=20&c=QuSHRHiIyJS9tRq2SUJB1ds2cFvIf-XI2l3RRqhl_JY="
    },
    {
      title: "Custom Application Developement",
      description: "Manage and grow your social media presence to build community and increase brand loyalty.",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS96UsiV2dDnOsjyh3c-6F_hSd66MzlsSpnTA&s"
    }
  ];
  const renderCard = (content, index) => (
    <div className="box" key={index}>
      <div className="card-image">
        <img src={content.image} alt={content.title} />
        <div className="layer">
          {/* <p>Check out 10 Best Design's updates for the top web design & development companies.</p> */}
          <a href="#"><i className='bx bx-link-external'></i></a>
        </div>
      </div>
      <div className="card-content">
        <h3><span>{content.title}</span></h3>
        <p>{content.description}</p>
        <Link to={`/Services/${index}`} className="read">Read More</Link> {/* Dynamic link to ServiceDetail */}
      </div>
    </div>
  );
  
  

  return (
    <section className="services" id="services">
      <div className="main-text">
        <h2 className='si'><span >About Our</span> Creative Services</h2>
        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit...</p> */}
      </div>
      <div className="services-content">
        {cardContent.map((content, index) => renderCard(content, index))}
      </div>
    </section>
  );
}

export default Services;