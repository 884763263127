import React, { useEffect } from 'react'; // Import useEffect from React
import './Msp.css'; // Import your CSS file for styling
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Header from './Header';

const Msp = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  // Function to handle close action
  const handleClose = () => {
    navigate('/'); // Change this to your desired route
  };

  return (
    <div className="managed-service-provider">
      <Header />
      
      {/* Close Icon */}
      <div className="close-icone" onClick={handleClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>

      <h1  className="heading">Managed Service Provider Staffing</h1>
      <h2>STAFFING WITHIN MSP PROGRAMS IS OUR EXPERTISE</h2>
      <p>
        We respond quickly and accurately with the best candidates.
      </p>
      <p>
        Successfully recruiting within a staffing Managed Service Provider (MSP) environment calls for a unique approach. One that emphasizes speed and quality while being cost-effective at the same time. It’s not easy. At least that’s what we’ve been told. But clearly, those people have never worked with Akraya.
      </p>
      <p>
        Infoway actively recruits within nearly all the leading managed staffing program provider environments. Our recruiting teams are not only experts at what they do, they use recruiting tools and techniques that allow them to respond quickly and accurately with the best candidates available. When you need a managed service provider for your contingent workforce, trust Akraya. We are built for the MSP staffing model. And we have the awards to prove it.
      </p>
      <img src="https://www.infowaygroup.com/sites/all/themes/infowaygroup/images/new/msps.png" alt="Managed Service Provider" />
      <Link to="/" className="back-button">Back</Link>
    </div>
  );
};

export default Msp;
